
import { Options, Vue } from "vue-class-component";
import AddButton from "@/components/utils/AddButton.vue";
import ButtonRefresh from "@/components/utils/ButtonRefresh.vue";
import ListItem from "@/components/utils/ListItem.vue";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import SessionsDetail from "@/views/LocalAdmin/Sessions/SessionsDetail.vue";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    AddButton,
    ButtonRefresh,
    ListItem,
    LoadingScreen,
    SessionsDetail,
    ErrorBox,
  },
  data() {
    return {
      sessions: null,
      loaded: false,
      loadingMessage: this.$t("global.defaults.loading"),
      sessionsDetailData: {},
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
    };
  },
  mounted() {
    this.getSessions();
    document.title =
      this.$t("global.defaults.session", 2) +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  updated() {
    if (this.$store.state.global.update === "session") this.getSessions();
  },
  methods: {
    getSessions() {
      this.loaded = false;
      this.$store
        .dispatch("getSessions")
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.$store.dispatch("changeUpdate", "");
            this.sessions = response.data;

            this.sessions.sort((a: any, b: any) => {
              return (
                (new Date(b.createdOn) as any) - (new Date(a.createdOn) as any)
              );
            });

            this.loaded = true;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    showSessionsDetail(session: Record<string, unknown>) {
      console.log(session);

      this.sessionsDetailData = session;
    },
  },
})
export default class Sessions extends Vue {}
