
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    loading: Boolean,
  },
})
export default class ButtonRefresh extends Vue {}
