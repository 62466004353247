
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["closeModal"],
  props: {
    text: String,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
})
export default class ModalWrapper extends Vue {}
