import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ac0024a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-4 grid grid-cols-1 auto-rows-min sm:grid-cols-1 gap-4 custom-height" }
const _hoisted_2 = {
  key: 0,
  class: "p-8 bg-white shadow"
}
const _hoisted_3 = { class: "text-2xl mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_InfoItem = _resolveComponent("InfoItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingScreen, {
      loaded: _ctx.loaded,
      message: _ctx.loadingMessage
    }, null, 8, ["loaded", "message"]),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("pages.usage.allowanceTitle")), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_InfoItem, {
                heading: _ctx.$t('pages.usage.currentLicense'),
                value: _ctx.usage.license
              }, null, 8, ["heading", "value"]),
              _createVNode(_component_InfoItem, {
                heading: _ctx.$t('pages.usage.licenseExpiry'),
                value: _ctx.getDate(_ctx.usage.licenseExpiry)
              }, null, 8, ["heading", "value"]),
              _createVNode(_component_InfoItem, {
                heading: _ctx.$t('pages.usage.hoursUsed'),
                value: _ctx.getHoursUsed()
              }, null, 8, ["heading", "value"]),
              _createVNode(_component_InfoItem, {
                heading: _ctx.$t('pages.usage.currentBalance'),
                value: _ctx.getHoursLeft(),
                noPadding: true
              }, null, 8, ["heading", "value"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}