
import { Options, Vue } from "vue-class-component";
import Header from "@/components/global/Header.vue"; // @ is an alias to /src
import Footer from "@/components/global/Footer.vue"; // @ is an alias to /src
import { isAccessPage } from "@/utils/isAccessPage";

@Options({
  components: {
    Header,
    Footer,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    checkAccessPage(route: string) {
      return isAccessPage(route);
    },
  },
})
export default class AppLayoutDefault extends Vue {}
