import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sm text-nucleus-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'pb-6': !_ctx.noPadding })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["mt-1 text-black", _ctx.statusColour])
    }, _toDisplayString(_ctx.value), 3)
  ], 2))
}