
import { Options, Vue } from "vue-class-component";
import DarkToggle from "@/components/global/DarkToggle.vue"; // @ is an alias to /src

@Options({
  props: {
    name: String,
    to: Object || String,
    noMenu: Boolean,
    canDelete: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
    canAddUsage: Boolean,
    canResend: Boolean,
    text: String,
    state: String,
  },
  emits: [
    "deleteCheck",
    "edit",
    "resend",
    "deactivate",
    "activate",
    "addUsage",
  ],
  components: {
    DarkToggle,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    logo() {
      return "logo_black.svg";
    },
    pageName() {
      return this.$route.name;
    },
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true;
    },
    deleteCheck() {
      this.toggleMenu();
      this.$emit("deleteCheck");
    },
    edit() {
      this.toggleMenu();
      this.$emit("edit");
    },
    deactivate() {
      this.toggleMenu();
      if (this.state === "ACTIVE") {
        this.$emit("deactivate");
      } else {
        this.$emit("activate");
      }
    },
    addUsage() {
      this.toggleMenu();
      this.$emit("addUsage");
    },
    resend() {
      this.toggleMenu();
      this.$emit("resend");
    },
  },
})
export default class HeaderMini extends Vue {}
