
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:value", "updateInputType"],
  props: {
    label: String,
    icon: String,
    placeholder: String,
    value: String,
    error: String,
    required: Boolean,
    id: String,
    maxLength: Number,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    // Update Model Value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue(event: any) {
      this.$emit("update:value", event.target.value);
    },
  },
})
export default class Input extends Vue {}
