import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center p-4 bg-nucleus-white dark:bg-nucleus-darkBlue" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "font-bold text-nucleus-text" }
const _hoisted_6 = {
  key: 0,
  class: "fixed top-20 left-0 p-2 w-full"
}
const _hoisted_7 = { class: "bg-nucleus-white shadow-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "block mr-4 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack())),
        "aria-label": 'Go Back to' + _ctx.name
      }, [
        _createElementVNode("span", {
          class: "block align-middle text-3xl text-nucleus-grey dark:text-white icon-arrow-left",
          title: _ctx.$t('titles.goBack')
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.name), 1)
    ]),
    (_ctx.menu)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.pageName === 'tenancyDetail')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteTenancyCheck())),
                  class: "p-4 cursor-pointer border-t border-gray-300 text-nucleus-error text-sm"
                }, _toDisplayString(_ctx.$t("global.defaults.deleteTenancy")), 1))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_click_away, _ctx.toggleMenu]
        ])
      : _createCommentVNode("", true)
  ]))
}