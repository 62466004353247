
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: [Error, String],
    content: [Error, String],
    fixed: Boolean,
  },
  emits: ["closeError"],
  methods: {
    closeError() {
      this.$emit("closeError");
    },
  },
})
export default class ErrorBox extends Vue {}
