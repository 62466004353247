
import { Options, Vue } from "vue-class-component";
import UserMenu from "@/components/global/UserMenu.vue"; // @ is an alias to /src
import { isAccessPage } from "@/utils/isAccessPage";
import LanguageMenu from "@/components/global/LanguageMenu.vue"; // @ is an alias to /src

@Options({
  components: {
    UserMenu,
    LanguageMenu,
  },
  computed: {
    showUserMenu() {
      let user = this.$store.getters.getUserObject;
      return user && user.attributes;
    },
    logo() {
      if (this.checkAccessPage(this.currentRouteName)) {
        return "logo_white.svg";
      } else {
        return "logo_black.svg";
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    checkAccessPage(route: string) {
      return isAccessPage(route);
    },
  },
})
export default class Home extends Vue {}
