import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-nucleus-error font-bold"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["value", "nameOption"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block w-full mb-1"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " * "))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("select", {
        id: _ctx.id,
        class: "block w-full border border-nucleus-darkestBlue rounded p-2 bg-nucleus-lightestBlue color-nucleus-darkestBlue",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: option.id,
            nameOption: option.name
          }, _toDisplayString(option.name), 9, _hoisted_6))
        }), 128))
      ], 40, _hoisted_5), [
        [_vModelSelect, _ctx.value]
      ])
    ])
  ]))
}