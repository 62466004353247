
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    icon: String,
    room: String,
    status: String,
    to: String,
    allowance: Object,
  },
  methods: {
    getIconColour() {
      const text = this.status.toLowerCase();
      switch (text) {
        case "started":
          return "text-white bg-nucleus-primaryBlue";
        default:
          return "text-nucleus-text bg-nucleus-iconBg";
      }
    },
    getStatusColor() {
      const text = this.status.toLowerCase();
      switch (text) {
        case "active":
        case "started":
        case "enabled":
        case "joined":
        case "verified":
        case "confirmed":
          return "text-nucleus-primaryBlue font-bold";
        case "pending":
          return "text-nucleus-primaryBlue";
        case "failed":
        case "declined":
        case "reset_required":
        case "force_password_change":
          return "text-nucleus-error font-bold";
        default:
          return "text-nucleus-text";
      }
    },
    getStatusName(name: string) {
      switch (name) {
        case "UNVERIFIED":
          return this.$t("status.unverified");
        case "VERIFICATION_REQUESTED":
          return this.$t("status.invited");
        case "VERIFIED":
          return this.$t("status.verified");
        case "UNCONFIRMED":
          return this.$t("status.unconfirmed");
        case "CONFIRMED":
          return this.$t("status.registered");
        case "ARCHIVED":
          return this.$t("status.archived");
        case "UNKNOWN":
          return this.$t("status.unknown");
        case "RESET_REQUIRED":
          return this.$t("status.passwordResetRequired");
        case "FORCE_CHANGE_PASSWORD":
          return this.$t("status.passwordChangeRequested");
        case "NOT_SENT":
          return this.$t("status.inviteNotSent");
        case "SENT":
          return this.$t("status.inviteSent");
        case "DECLINED":
          return this.$t("status.declined");
        case "JOINED":
          return this.$t("status.joined");
        case "PENDING":
          return this.$t("status.prepared");
        case "STARTING":
          return this.$t("status.starting");
        case "STARTED":
          return this.$t("status.active");
        case "FINISHED":
          return this.$t("status.finished");
        case "FAILED":
          return this.$t("status.failedToStart");
        case "DISABLED":
          return this.$t("status.deactivated");
        case "ACTIVE":
          return this.$t("status.active");
        default:
          return name;
      }
    },
    getRoleName(name: string) {
      switch (name) {
        case "LOCAL_ADMIN":
          return this.$t("role.localAdmin");
        case "STAFF":
          return this.$t("role.staff");
        case "SERVICE_ADMIN":
          return this.$t("role.serviceAdmin");
        default:
          return name;
      }
    },
  },
})
export default class ListItem extends Vue {}
