
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import TabLink from "@/components/utils/TabLink.vue";

@Options({
  components: {
    TabLink,
    HeaderShadow,
  },
  computed: {
    staff() {
      return this.$store.getters.getStaff;
    },
  },
})
export default class LocalAdmin extends Vue {}
