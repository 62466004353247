
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    type: String,
    loading: Boolean,
  },
  methods: {
    ifLoading() {
      if (this.loading) return "opacity-75 cursor-not-allowed";
    },
  },
})
export default class Home extends Vue {}
