import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative mr-5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "fixed z-20 top-header right-0 text-right"
}
const _hoisted_4 = { class: "bg-nucleus-white shadow-lg" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu())),
      class: "align-middle cursor-pointer leading-none"
    }, [
      _createElementVNode("img", {
        class: "h-6 w-auto max-w-none block",
        src: require(`@/assets/flags/` + _ctx.findCurrentLanguage().flag),
        alt: ""
      }, null, 8, _hoisted_2)
    ]),
    (_ctx.menu)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.changeLanguage(language.locale)),
                class: _normalizeClass(["p-5 opacity-50 hover:bg-gray-50 hover:opacity-100 cursor-pointer transition-all", { 'opacity-100': _ctx.currentLanguage === language.locale }])
              }, [
                _createElementVNode("img", {
                  class: "h-6 w-auto max-w-none block",
                  src: require(`@/assets/flags/` + language.flag),
                  alt: ""
                }, null, 8, _hoisted_6)
              ], 10, _hoisted_5))
            }), 128))
          ])
        ])), [
          [_directive_click_away, _ctx.toggleMenu]
        ])
      : _createCommentVNode("", true)
  ]))
}