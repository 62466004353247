
import { Options, Vue } from "vue-class-component";
import { Auth, Hub } from "aws-amplify";
import { HubPayload } from "@/store/types/HubPayload";

@Options({
  async beforeMount() {
    this.checkLogin();
    this.initLanguage();
  },
  // mounted() {
  //   this.addThemeClass(this.theme);
  // },
  // computed: {
  //   theme() {
  //     return this.$store.getters.getTheme;
  //   },
  // },
  // watch: {
  //   theme(newTheme) {
  //     this.addThemeClass(newTheme);
  //   },
  // },
  methods: {
    onAuthEvent(payload: HubPayload) {
      if (payload.event !== "signOut") {
        console.log("User data", payload);

        if (payload.data?.attributes?.name) {
          this.setUserObject(payload.data);
          if (
            payload.data?.signInUserSession?.idToken?.payload["cognito:groups"]
          ) {
            this.setRoles(
              payload.data.signInUserSession.idToken.payload["cognito:groups"]
            );
          }
        }
      } else {
        console.log("USER NOT LOGGED IN");
        this.removeAccess();
      }
    },
    // addThemeClass(theme: string) {
    //   if (theme === "light") {
    //     document.querySelector("html")?.classList.remove("dark");
    //   } else {
    //     document.querySelector("html")?.classList.add("dark");
    //   }
    // },
    async checkLogin() {
      // Start listening
      Hub.listen("auth", (data) => {
        const { payload } = data;
        this.onAuthEvent(payload);
      });
      try {
        const user = await Auth.currentAuthenticatedUser();

        this.setUserObject(user);
        this.setRoles(user.signInUserSession.idToken.payload["cognito:groups"]);
      } catch (err) {
        console.log("USER NOT LOGGED IN", err);
        this.removeAccess();
      }
    },
    setUserObject(object: Record<string, unknown>) {
      if (object) {
        this.$store.dispatch("setUserObject", object);
        this.initLanguage();
      }
    },
    setRoles(roles: []) {
      if (roles) {
        this.$store.dispatch("setRoles", roles);
      }
    },
    removeAccess() {
      this.$store.dispatch("setUserObject", null);
      this.$store.dispatch("setRoles", []);
    },
    initLanguage() {
      this.$store.dispatch("initLanguage");
      this.$root.$i18n.locale = this.$store.getters.getLanguage;
    },
  },
})
export default class Home extends Vue {}
