import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white p-4 overflow-y-auto" }
const _hoisted_2 = { class: "mb-6" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_TextLink = _resolveComponent("TextLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: true,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TextLink, {
          to: "/",
          name: "Back to Sign In"
        })
      ]),
      (_ctx.doc)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.doc
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}