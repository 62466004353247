
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:value"],
  props: {
    id: String,
    name: String,
    value: Boolean,
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateInput(e: any) {
      if (e.target.checked) {
        this.$emit("update:value", true);
      } else {
        this.$emit("update:value", false);
      }
    },
  },
})
export default class Checkbox extends Vue {}
