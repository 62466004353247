
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    to: String,
    name: String,
    white: Boolean,
  },
})
export default class TextLink extends Vue {}
