
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: String,
    to: String,
    linkText: String,
  },
})
export default class HintLink extends Vue {}
