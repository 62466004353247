import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";
// import theme from "./modules/theme";
import auth from "./modules/auth";
import tenancy from "./modules/tenancy";
import session from "./modules/session";
import user from "./modules/user";
import global from "./modules/global";

// const userState = createPersistedState({
//   paths: ["user"],
// });

export default createStore({
  modules: {
    // theme: theme,
    auth: auth,
    tenancy: tenancy,
    session: session,
    user: user,
    global: global,
  },
  // plugins: [createPersistedState()],
});
