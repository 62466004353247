import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-4 flex justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "pb-4 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingScreen, {
      loaded: _ctx.loaded,
      message: _ctx.loadingMessage
    }, null, 8, ["loaded", "message"]),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AddButton, {
              class: "w-full",
              name: _ctx.$t('global.defaults.addSession'),
              to: "/local-admin/sessions/add"
            }, null, 8, ["name"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessions, (session) => {
            return (_openBlock(), _createBlock(_component_ListItem, {
              class: "cursor-pointer",
              key: session.id,
              icon: "session",
              name: session.name,
              status: session.state,
              to: '/local-admin/sessions/' + session.id
            }, null, 8, ["name", "status", "to"]))
          }), 128)),
          (!_ctx.sessions || _ctx.sessions?.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("global.defaults.noUsers", {
            userType: _ctx.$t("global.defaults.session", 2),
          })), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}