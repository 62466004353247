import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import store from "@/store";

// Service Admin
import ServiceAdminIndex from "@/views/ServiceAdmin/Index.vue";
// Service Admin - Tenancies
import Tenancies from "@/views/ServiceAdmin/Tenancies/Tenancies.vue";
import TenanciesDetail from "@/views/ServiceAdmin/Tenancies/TenanciesDetail.vue";
import TenanciesAdd from "@/views/ServiceAdmin/Tenancies/TenanciesAdd.vue";
import TenanciesEdit from "@/views/ServiceAdmin/Tenancies/TenanciesEdit.vue";

// Local Admin
import LocalAdminIndex from "@/views/LocalAdmin/Index.vue";
// Local Admin - Remote users
import RemoteUsers from "@/views/LocalAdmin/RemoteUsers/RemoteUsers.vue";
import RemoteUsersDetail from "@/views/LocalAdmin/RemoteUsers/RemoteUsersDetail.vue";
import RemoteUsersAdd from "@/views/LocalAdmin/RemoteUsers/RemoteUsersAdd.vue";
import RemoteUsersEdit from "@/views/LocalAdmin/RemoteUsers/RemoteUsersEdit.vue";
// Local Admin - Local users
import LocalUsers from "@/views/LocalAdmin/LocalUsers/LocalUsers.vue";
import LocalUsersDetail from "@/views/LocalAdmin/LocalUsers/LocalUsersDetail.vue";
import LocalUsersAdd from "@/views/LocalAdmin/LocalUsers/LocalUsersAdd.vue";
import LocalUsersEdit from "@/views/LocalAdmin/LocalUsers/LocalUsersEdit.vue";
// Local Admin - Sessions
import Sessions from "@/views/LocalAdmin/Sessions/Sessions.vue";
import SessionsDetail from "@/views/LocalAdmin/Sessions/SessionsDetail.vue";
import SessionsAdd from "@/views/LocalAdmin/Sessions/SessionsAdd.vue";
import SessionsEdit from "@/views/LocalAdmin/Sessions/SessionsEdit.vue";
// Local Admin - Usage
import Usage from "@/views/LocalAdmin/Usage.vue";
// Login - Verify
import Verify from "@/views/Verify.vue";
import EulaPrivacyPolicy from "@/views/EulaPrivacyPolicy/Index.vue";
import ChangeLog from "@/views/ChangeLog/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/signin",
    name: "Home",
  },
  {
    path: "/no-access",
    name: "NoAccess",
    component: () =>
      import(/* webpackChunkName: "no-access" */ "../views/NoAccess.vue"),
  },
  {
    path: "/style-guide",
    name: "StyleGuide",
    component: () =>
      import(/* webpackChunkName: "style-guide" */ "../views/StyleGuide.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "/signin",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/eula-privacy-policy",
    name: "EulaPrivacyPolicy",
    component: EulaPrivacyPolicy,
  },
  {
    path: "/changelog",
    name: "ChangeLog",
    component: ChangeLog,
  },
  {
    path: "/verify",
    name: "verify",
    component: Verify,
  },
  {
    path: "/service-admin",
    redirect: "/service-admin/tenancies",
    name: "serviceAdmin",
    meta: { requiresAuth: true, serviceAdminAuth: true },
    component: ServiceAdminIndex,
    // Service Admin - Tenancies
    children: [
      {
        path: "tenancies",
        name: "tenancies",
        component: Tenancies,
        children: [
          {
            path: ":tenancyId",
            name: "tenancyDetail",
            component: TenanciesDetail,
          },
          {
            path: ":tenancyId/edit",
            name: "editTenancy",
            component: TenanciesEdit,
          },
        ],
      },
      {
        path: "tenancies/add",
        name: "tenancyAdd",
        component: TenanciesAdd,
      },
    ],
  },
  // Local Admin
  {
    path: "/local-admin",
    redirect: "/local-admin/sessions",
    name: "localAdmin",
    meta: { requiresAuth: true, localAdminAuth: true },
    component: LocalAdminIndex,
    // Local Admin - Sessions
    children: [
      {
        path: "sessions",
        name: "sessions",
        component: Sessions,
      },
      {
        path: "sessions/:sessionId",
        name: "sessionsDetail",
        component: SessionsDetail,
      },
      {
        path: "sessions/add",
        name: "sessionsAdd",
        component: SessionsAdd,
      },
      {
        path: "sessions/:sessionId/edit",
        name: "sessionsEdit",
        component: SessionsEdit,
      },
      // Local Admin - Remote Users
      {
        path: "remote-users",
        name: "remoteUsers",
        component: RemoteUsers,
        children: [
          {
            path: ":userId",
            name: "remoteUsersDetail",
            component: RemoteUsersDetail,
          },
          {
            path: ":userId/edit",
            name: "remoteUsersEdit",
            component: RemoteUsersEdit,
          },
        ],
      },
      {
        path: "remote-users/add",
        name: "remoteUsersAdd",
        component: RemoteUsersAdd,
      },
      // Local Admin - Local Users
      {
        path: "local-users",
        name: "localUsers",
        component: LocalUsers,
        children: [
          {
            path: ":userId",
            name: "localUsersDetail",
            component: LocalUsersDetail,
          },
          {
            path: ":userId/edit",
            name: "localUsersEdit",
            component: LocalUsersEdit,
          },
        ],
      },
      {
        path: "local-users/add",
        name: "localUsersAdd",
        component: LocalUsersAdd,
      },
      // Local Admin - Usage
      {
        path: "usage",
        component: Usage,
      },
    ],
  },
  {
    path: "/remote-client",
    name: "RemoteClient",
    component: () =>
      import(
        /* webpackChunkName: "remote-client" */ "../views/RemoteClient/Index.vue"
      ),
    children: [
      // {
      //   path: "session-select",
      //   name: "RemoteClientSelectSession",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "remote-client_session-select" */ "../views/RemoteClient/SessionSelect.vue"
      //     ),
      // },
      {
        path: "session",
        name: "RemoteClientSession",
        component: () =>
          import(
            /* webpackChunkName: "remote-client_session" */ "../views/RemoteClient/Session.vue"
          ),
      },
      {
        path: "access/:inviteId",
        name: "RemoteClientAccessSession",
        component: () =>
          import(
            /* webpackChunkName: "remote-client_access-session" */ "../views/RemoteClient/AccessSession.vue"
          ),
      },
      {
        path: "ended",
        name: "RemoteClientEnded",
        component: () =>
          import(
            /* webpackChunkName: "remote-client_access-session" */ "../views/RemoteClient/Ended.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
router.beforeEach((to: any, from: any, next: any) => {
  const userObject = store.getters.getUserObject;
  const jwt = userObject ? userObject.signInUserSession.idToken.jwtToken : null;
  const serviceAdmin = store.getters.getServiceAdmin;
  const localAdmin = store.getters.getLocalAdmin;
  const staff = store.getters.getStaff;
  const phoneVerified = userObject?.attributes.phone_number_verified
    ? userObject.attributes.phone_number_verified
    : false;
  const agreement = userObject?.attributes["custom:agreement"]
    ? userObject.attributes["custom:agreement"]
    : "0";
  const region = userObject?.attributes["custom:region"];

  if (to.name === "Login") {
    if (!jwt || !phoneVerified) {
      next();
    } else if (jwt && serviceAdmin) {
      next({ name: "serviceAdmin" });
    } else if (jwt && (localAdmin || staff)) {
      next({ name: "localAdmin" });
    } else {
      next();
    }
  } else if (to.meta.requiresAuth) {
    if (!jwt) {
      router.push({ path: "/signin" });
    } else {
      if ((serviceAdmin || localAdmin || staff) && !phoneVerified) {
        router.push({ path: "/signin" });
      }
      if ((localAdmin || staff) && agreement === "0") {
        if (region) {
          router.push({ path: "/eula-privacy-policy", query: { region } });
        } else {
          router.push({ path: "/eula-privacy-policy" });
        }
      }
      if (to.meta.serviceAdminAuth) {
        if (serviceAdmin) {
          return next();
        } else {
          router.push({ path: "/no-access" });
        }
      }
      if (to.meta.localAdminAuth) {
        if (localAdmin || staff) {
          return next();
        } else {
          router.push({ path: "/no-access" });
        }
      }
      if (to.meta.localAdminUserAuth) {
        if (localAdmin) {
          return next();
        } else {
          router.push({ path: "/no-access" });
        }
      }
      if (to.meta.staffUserAuth) {
        if (staff) {
          return next();
        } else {
          router.push({ path: "/no-access" });
        }
      }
      return next();
    }
  } else {
    return next();
  }
});

export default router;
