
import { Options, Vue } from "vue-class-component";

import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src

@Options({
  components: {
    Button,
    ButtonSecondary,
  },
  emits: ["closeModal", "deleteMe"],
  props: {
    text: String,
  },
  data() {
    return {
      deleting: false,
    };
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    deleteMe() {
      this.$emit("deleteMe");
      this.$emit("closeModal");
    },
  },
})
export default class ModalDeleteCheck extends Vue {}
