export function mapAwsUserStatus(name: string): string {
  switch (name) {
    case "UNVERIFIED":
      return "status.unverified";
    case "VERIFICATION_REQUESTED":
      return "status.invited";
    case "VERIFIED":
      return "status.verified";
    case "UNCONFIRMED":
      return "status.unconfirmed";
    case "CONFIRMED":
      return "status.registered";
    case "ARCHIVED":
      return "status.archived";
    case "UNKNOWN":
      return "status.unknown";
    case "RESET_REQUIRED":
      return "status.passwordResetRequired";
    case "FORCE_CHANGE_PASSWORD":
      return "status.passwordChangeRequested";
    case "NOT_SENT":
      return "status.inviteNotSent";
    case "SENT":
      return "status.inviteSent";
    case "DECLINED":
      return "status.declined";
    case "JOINED":
      return "status.joined";
    case "PENDING":
      return "status.prepared";
    case "STARTING":
      return "status.starting";
    case "STARTED":
      return "status.active";
    case "FINISHED":
      return "status.finished";
    case "FAILED":
      return "status.failedToStart";
    case "ACTIVE":
      return "status.active";
    case "DISABLED":
      return "status.deactivated";
    default:
      return name;
  }
}
