
import { Options, Vue } from "vue-class-component";
import TextLink from "@/components/utils/TextLink.vue";

@Options({
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  components: {
    TextLink,
  },
})
export default class Footer extends Vue {}
