
import { Options, Vue } from "vue-class-component";
import AddButton from "@/components/utils/AddButton.vue";
import ListItem from "@/components/utils/ListItem.vue";
import ListItemBasic from "@/components/utils/ListItemBasic.vue";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    AddButton,
    ListItem,
    ListItemBasic,
    LoadingScreen,
    ErrorBox,
    Input,
  },
  data() {
    return {
      users: null,
      usersOriginal: null,
      loaded: false,
      loadingMessage: this.$t("global.defaults.loading"),
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      nameFilter: "",
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  mounted() {
    this.getUsers();
    document.title =
      this.$t("global.defaults.remoteUser", 2) +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  updated() {
    if (this.$store.state.global.update === "remoteUser") this.getUsers();
  },
  computed: {
    staff() {
      return this.$store.getters.getStaff;
    },
  },
  methods: {
    getUsers() {
      this.$store
        .dispatch("getRemoteUsers")
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.$store.dispatch("changeUpdate", "");
            this.users = this.orderByName(response.data);
            this.usersOriginal = this.orderByName(response.data);
            this.loaded = true;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orderByName(object: Array<any>) {
      return object.sort((a, b) => a.name.localeCompare(b.name));
    },
    filterByName(name: string) {
      console.log(name);
      this.users = this.usersOriginal.filter((user: any) =>
        user.name.toLowerCase().includes(name.toLowerCase())
      );
    },
    clearInput() {
      this.nameFilter = "";
      this.filterByName(this.nameFilter);
    },
  },
})
export default class RemoteUsers extends Vue {}
