
import { Options, Vue } from "vue-class-component";

import { Auth } from "aws-amplify";
import router from "@/router";

@Options({
  data() {
    return {
      menu: false,
    };
  },
  props: {
    currentPage: String,
  },
  computed: {
    user() {
      return this.$store.getters.getUserObject;
    },
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true;
    },
    async logout() {
      try {
        await Auth.signOut();
        this.toggleMenu();
        this.removeAccess();
        router.go(0);
      } catch (err) {
        console.log("ERROR", err);
      }
    },

    removeAccess() {
      this.$store.dispatch("setUserObject", null);
      this.$store.dispatch("setRoles", []);
    },
  },
})
export default class UserMenu extends Vue {}
