
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import TabLink from "@/components/utils/TabLink.vue";

@Options({
  components: {
    TabLink,
    HeaderShadow,
  },
})
export default class ServiceAdmin extends Vue {}
