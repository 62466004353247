import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppLayout from "./layouts/AppLayout.vue";
import "./assets/tailwind.css";

import Amplify from "aws-amplify";
import VueClickAway from "vue3-click-away";
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import ja from "./locales/ja.json";

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    mandatorySignIn: true,
  },
  storage: sessionStorage,
});

export const i18n = createI18n({
  locale: process.env.VUE_APP_LANGUAGE || "en",
  fallbackLocale: process.env.VUE_APP_FALLBACK_LANGUAGE || "en",
  messages: {
    en: en,
    ja: ja,
  },
  legacy: false,
  globalInjection: true,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(i18n)
  .component("AppLayout", AppLayout)
  .mount("#app");
