import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center p-4 bg-nucleus-white dark:bg-nucleus-darkBlue" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "font-bold text-nucleus-text capitalize" }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 1,
  class: "fixed top-20 left-0 z-20 p-2 w-full"
}
const _hoisted_8 = { class: "bg-nucleus-white shadow-lg" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.to }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "block mr-4",
            "aria-label": 'Go Back to' + _ctx.name
          }, [
            _createElementVNode("span", {
              class: "block align-middle text-3xl text-nucleus-grey dark:text-white icon-arrow-left",
              title: _ctx.$t('titles.goBack')
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.name), 1)
    ]),
    (!_ctx.noMenu)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu())),
          class: "block",
          "aria-label": 'Toggle menu'
        }, [
          _createElementVNode("span", {
            class: "icon-kebab block align-middle text-3xl text-nucleus-grey dark:text-white",
            title: _ctx.$t('titles.menu')
          }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.canEdit)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit())),
                  class: "p-4 cursor-pointer hover:bg-gray-100"
                }, _toDisplayString(_ctx.$t("global.defaults.editItem", { item: _ctx.text })), 1))
              : _createCommentVNode("", true),
            (_ctx.canAddUsage)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addUsage())),
                  class: "p-4 cursor-pointer hover:bg-gray-100"
                }, _toDisplayString(_ctx.$t("pages.tenancy.addCredits")), 1))
              : _createCommentVNode("", true),
            (_ctx.canDeactivate)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deactivate())),
                  class: "p-4 cursor-pointer hover:bg-gray-100"
                }, [
                  (_ctx.state === 'ACTIVE')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("global.defaults.deactivateItem", { item: _ctx.text })), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("global.defaults.activateItem", { item: _ctx.text })), 1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.canResend)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resend())),
                  class: "p-4 cursor-pointer hover:bg-gray-100"
                }, _toDisplayString(_ctx.$t("pages.tenancy.resendVerification")), 1))
              : _createCommentVNode("", true),
            (_ctx.canDelete)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteCheck())),
                  class: "p-4 cursor-pointer hover:bg-gray-100 border-t border-gray-300 text-nucleus-error text-sm"
                }, _toDisplayString(_ctx.$t("global.defaults.deleteItem", { item: _ctx.text })), 1))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_click_away, _ctx.toggleMenu]
        ])
      : _createCommentVNode("", true)
  ]))
}