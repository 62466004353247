
import { Options, Vue } from "vue-class-component";
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import SelectCountry from "@/components/utils/SelectCountry.vue"; // @ is an alias to /src
import HintLink from "@/components/utils/HintLink.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src

import CountryCodesJson from "@/store/data/countryCodes.json"; // @ is an alias to /src
import CountryCodesCommonJson from "@/store/data/countryCodesCommon.json"; // @ is an alias to /src
import ApiVersionOptionsJson from "@/store/data/apiVersionOptions.json";
import LanguagesJson from "@/store/data/languages.json";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import parsePhoneNumber from "libphonenumber-js";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderMini,
    HeaderShadow,
    InfoItem,
    Input,
    Select,
    SelectCountry,
    HintLink,
    Button,
    ButtonSecondary,
    ErrorBox,
  },
  data() {
    return {
      apiVersionOptions: ApiVersionOptionsJson,
      countryCodes: CountryCodesJson,
      countryCodesCommon: CountryCodesCommonJson,
      languages: LanguagesJson,
      ipAddress: "",
      error: {
        tenancy: {
          contact: {
            email: "",
            phone: "",
          },
          ipAddress: "",
        },
      },
      usage: null,
      passwordInputType: "password",
      passwordIcon: "show",
      loading: false,
      correctNumber: "",
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  computed: {
    tenancy() {
      let tenancy = this.$store.state.tenancy.tenancy;
      if (tenancy.licenseExpiry) {
        tenancy.licenseExpiry = tenancy.licenseExpiry.slice(0, 10);
      }
      return tenancy;
    },
  },
  mounted() {
    this.getCountryCallingCode();
  },
  methods: {
    getCountryCallingCode() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let phoneNumber: any;
      phoneNumber = parsePhoneNumber(this.tenancy.contact.phone);
      if (phoneNumber) {
        this.tenancy.contact.phone = phoneNumber.nationalNumber;
        this.tenancy.contact.countryCode = String(
          "+" + phoneNumber.countryCallingCode
        );
      }
    },
    editTenancy() {
      this.loading = true;

      const number = this.correctNumber
        ? this.correctNumber
        : this.tenancy.contact.phone;

      this.tenancy.contact.phone = number;

      if (this.ipAddress) {
        if (!this.tenancy.ipWhitelist) {
          this.tenancy.ipWhitelist = [];
        }
        this.tenancy.ipWhitelist.push(this.ipAddress);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let data: any = {
        name: this.tenancy.name,
        contact: this.tenancy.contact,
        ipWhitelist: this.tenancy.ipWhitelist,
        license: this.tenancy.license,
        licenseExpiry: new Date(this.tenancy.licenseExpiry).toISOString(),
        api: {
          username: this.tenancy.api.username,
          version: this.tenancy.api.version,
        },
        language: this.tenancy.language,
        maxBroadcasts: parseInt(this.tenancy.maxBroadcasts),
      };

      if (this.tenancy.api.password) {
        data.api.password = this.tenancy.api.password;
      }

      this.$store
        .dispatch("editTenancy", {
          tenancyId: this.tenancy.tenantId,
          data: data,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.data) {
            console.log("complete", response);
            this.$store.dispatch("changeUpdate", "tenancy");
            this.$router.push({
              name: "tenancyDetail",
              params: { tenancyId: this.$route.params.tenancyId },
            });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    deleteIp(index: number) {
      console.log(index);
      this.tenancy.ipWhitelist.splice(index, 1);
    },
    validateEmail(email: string) {
      const mailformat =
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (this.required(email)) {
        if (!email.match(mailformat)) {
          this.error.tenancy.contact.email = this.$t(
            "inputs.email.errorInvalid"
          );
          return false;
        } else {
          this.error.tenancy.contact.email = "";
          return true;
        }
      } else {
        return false;
      }
    },
    validatePhoneNumber(code: any, phone: any) {
      const phoneNumber = parsePhoneNumber(
        code + phone.replace(/^0+/, "").replace(/\s/g, "")
      );

      if (this.required(phone)) {
        if (phoneNumber?.isValid()) {
          this.error.tenancy.contact.phone = "";
          this.correctNumber = phoneNumber.number;
          return true;
        } else {
          this.error.tenancy.contact.phone = this.$t(
            "inputs.phone.errorInvalid"
          );
          return false;
        }
      } else {
        return false;
      }
    },
    validateIpAddress(ipAddress: string) {
      const ipv4regex =
        // eslint-disable-next-line no-useless-escape
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

      const ipv4cidrregex =
        // eslint-disable-next-line no-useless-escape
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9]))$/;

      if (
        !ipAddress.match(ipv4regex) &&
        !ipAddress.match(ipv4cidrregex) &&
        ipAddress.length > 0
      ) {
        this.error.tenancy.ipAddress = this.$t("inputs.ipAddress.errorInvalid");
        return false;
      } else {
        this.error.tenancy.ipAddress = "";
        return true;
      }
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
  },
})
export default class TenancyAdd extends Vue {}
