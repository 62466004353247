
import { Options, Vue } from "vue-class-component";
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import InfoItemList from "@/components/utils/InfoItemList.vue"; // @ is an alias to /src
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ModalConfirmation from "@/components/global/ModalConfirmation.vue";
import ModalDeleteCheck from "@/components/global/ModalDeleteCheck.vue";
import ModalWrapper from "@/components/global/ModalWrapper.vue";
import TextLinkBasic from "@/components/utils/TextLinkBasic.vue"; // @ is an alias to /src
import ErrorBox from "@/components/utils/ErrorBox.vue";
import Input from "@/components/utils/Input.vue";
import Button from "@/components/utils/Button.vue";
import ApiVersionOptionsJson from "@/store/data/apiVersionOptions.json";
import RegionsJson from "@/store/data/regions.json";
import LanguagesJson from "@/store/data/languages.json";

import { getPrettyDate } from "@/utils/prettyDate";
import { mapAwsUserStatus } from "@/utils/awsMappings";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderMini,
    HeaderShadow,
    InfoItem,
    InfoItemList,
    LoadingScreen,
    ModalConfirmation,
    ModalDeleteCheck,
    ModalWrapper,
    TextLinkBasic,
    ErrorBox,
    Input,
    Button,
  },
  data() {
    return {
      tenancy: {},
      connectionStatus: {},
      loaded: false,
      loading: false,
      deleteCheck: false,
      deactivateCheck: false,
      confirm: undefined,
      loadingMessage: this.$t("global.defaults.loadingTenancy"),
      downloadPending: false,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      apiVersionOptions: ApiVersionOptionsJson,
      regions: RegionsJson,
      languages: LanguagesJson,
      addUsageShow: false,
      creditsToAdd: 0,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  beforeMount() {
    this.getTenancy();
  },
  mounted() {
    document.title =
      this.$t("global.defaults.tenancy", 1) +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    mapState(state: string) {
      return this.$t(mapAwsUserStatus(state));
    },
    getTenancy() {
      this.$store
        .dispatch("getTenancy", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            this.tenancy = response.data;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    deleteMe() {
      this.loaded = false;
      this.loadingMessage = this.$t("global.defaults.deletingTenancy");
      this.$store
        .dispatch("deleteTenancy", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            console.log(response.data);
            this.$store.dispatch("changeUpdate", "tenancy");
            this.$router.push({ name: "tenancies" });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    confirmDeactivateTenancy() {
      this.confirm = {
        text: this.$t("pages.tenancy.deactivateMessage"),
        yes: () => {
          this.deactivateTenancy();
          this.confirm = undefined;
        },
        no: () => (this.confirm = undefined),
      };
    },
    deactivateTenancy() {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.tenancy.deactivating");
      this.$store
        .dispatch("disableTenancy", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            console.log(response.data);
            this.getTenancy();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    activateTenancy() {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.tenancy.activating");
      this.$store
        .dispatch("enableTenancy", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            console.log(response.data);
            this.getTenancy();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    editTenancy() {
      this.$router.push({
        name: "editTenancy",
        params: { tenancyId: this.$route.params.tenancyId },
      });
    },
    testConnection() {
      this.connectionStatus = {};
      this.$store
        .dispatch("getTenancyConnectionStatus", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.connectionStatus = response.data;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    downloadVpnCert() {
      this.downloadPending = true;
      this.$store
        .dispatch("generateClientCertificate", this.$route.params.tenancyId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.data && response.data.url) {
            console.log(response.data);
            window.open(response.data.url, "_blank");
          }
          this.downloadPending = false;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.downloadPending = false;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    confirmReserveIps() {
      this.confirm = {
        text: this.$t("pages.tenancy.reserveIpMessage"),
        yes: () => {
          this.reserveIps();
          this.confirm = undefined;
        },
        no: () => (this.confirm = undefined),
      };
    },
    reserveIps() {
      this.$store
        .dispatch("reserveMediaServerIps", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          console.log(response);
          if (response) {
            this.getTenancy();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    confirmReleaseIps() {
      this.confirm = {
        text: this.$t("pages.tenancy.releaseIpMessage"),
        yes: () => {
          this.releaseIps();
          this.confirm = undefined;
        },
        no: () => (this.confirm = undefined),
      };
    },
    releaseIps() {
      this.$store
        .dispatch("releaseMediaServerIps", this.$route.params.tenancyId)
        .then((response: Record<string, unknown>) => {
          console.log(response);
          if (response) {
            this.getTenancy();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    getHoursLeft() {
      let remaining =
        this.tenancy.creditsTotal -
        this.tenancy.creditsUsed -
        this.tenancy.creditsExpired;
      let remainingText;
      if (remaining === 0) {
        remainingText = this.$t("pages.usage.0Hours");
      } else if (remaining > -1 && remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebt");
      } else if (remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebtAmount", {
          remaining: Math.floor(Math.abs(remaining)),
        });
      } else if (remaining > 0 && remaining < 1) {
        remainingText = this.$t("pages.usage.lessThan1Remaining");
      } else {
        remainingText = this.$t("pages.usage.remaining", {
          remaining: Math.floor(remaining),
        });
      }
      return remainingText;
    },
    getHoursUsed() {
      let usedText;
      if (this.tenancy.creditsUsed > 0 && this.tenancy.creditsUsed < 1) {
        usedText = this.$t("pages.usage.lessThan1Used", {
          remaining: this.tenancy.creditsTotal,
        });
      } else {
        usedText = this.$t("pages.usage.used", {
          used: Math.floor(this.tenancy.creditsUsed),
          total: this.tenancy.creditsTotal,
        });
      }
      return usedText;
    },
    getDate(date: Date) {
      return getPrettyDate(date);
    },
    validateCredits(credits: number) {
      if (credits > 0) {
        return true;
      } else {
        return false;
      }
    },
    addUsage() {
      this.$store
        .dispatch("addUsageCredits", {
          tenancyId: this.$route.params.tenancyId,
          credits: this.creditsToAdd,
        })
        .then((response: Record<string, unknown>) => {
          console.log(response);
          if (response) {
            this.getTenancy();
          }
          this.addUsageShow = false;
          this.creditsToAdd = 0;
          this.loading = false;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
          this.addUsageShow = false;
          this.creditsToAdd = 0;
          this.loading = false;
        });
      console.log(this.usage);
      this.loading = true;
    },
  },
})
export default class TenancyDetail extends Vue {}
