
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:value", "updateInputType", "clearInput"],
  props: {
    inputType: String,
    label: String,
    icon: String,
    title: String,
    placeholder: String,
    value: String,
    autocomplete: String,
    error: String,
    required: Boolean,
    id: String,
    maxLength: Number,
    min: Number,
    whiteBg: Boolean,
    filter: Boolean,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    // Update Model Value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue(event: any) {
      this.$emit("update:value", event.target.value);
    },
    togglePassword() {
      if (this.id.includes("password")) {
        const type = this.inputType === "password" ? "text" : "password";
        const icon = this.inputType === "password" ? "hide" : "show";
        console.log(type);

        this.$emit("updateInputType", { type: type, icon: icon });
      }
    },
    autocompleteCheck() {
      if (this.autocomplete) {
        return this.autocomplete;
      } else {
        return "on";
      }
    },
    clearInput() {
      this.$emit("clearInput");
    },
  },
})
export default class Input extends Vue {}
