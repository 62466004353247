import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7c5a372"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6 relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-nucleus-error font-bold"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["id", "type", "value", "placeholder", "autocomplete", "maxlength", "min", "required"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = {
  key: 0,
  class: "text-nucleus-error absolute text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block w-full mb-1 text-black"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " * "))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        id: _ctx.id,
        class: _normalizeClass(["block w-full border border-nucleus-darkestBlue rounded p-2 color-nucleus-darkestBlue focus:outline-none focus:ring-2 focus:ring-nucleus-primaryBlue focus:border-transparent", {
          'border-nucleus-error focus:ring-nucleus-error': _ctx.error,
          'bg-white': _ctx.whiteBg,
          'bg-nucleus-lightestBlue': !_ctx.whiteBg,
        }]),
        type: _ctx.inputType,
        value: _ctx.value,
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocompleteCheck(),
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false)),
        maxlength: _ctx.maxLength,
        min: _ctx.min,
        required: _ctx.required
      }, null, 42, _hoisted_5),
      _createElementVNode("span", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.togglePassword())),
        class: _normalizeClass(["text-xl absolute top-1.5 right-2.5 cursor-pointer", [
          'icon-' + _ctx.icon,
          {
            'text-nucleus-darkestBlue': _ctx.isFocused,
            'text-gray-400': !_ctx.isFocused,
          },
        ]]),
        title: _ctx.title
      }, null, 10, _hoisted_6),
      (_ctx.filter && _ctx.value)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearInput())),
            class: "text-3xl absolute top-0 right-1.5 flex justify-center items-center h-full cursor-pointer icon-clear text-nucleus-darkestBlue hover:text-nucleus-primaryBlue transition-all",
            title: _ctx.$t('titles.clearInput')
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}