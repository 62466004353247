
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    loaded: Boolean,
    message: String,
  },
  data() {
    return {
      hidden: false,
    };
  },
  watch: {
    loaded: function (newVal) {
      if (newVal) this.hide();
      if (newVal === false) this.hidden = false;
    },
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.hidden = true;
      }, 500);
    },
  },
})
export default class HeaderMini extends Vue {}
