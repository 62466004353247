/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPrettyDate(date: Date) {
  date = new Date(date);

  // const year: any = date.getFullYear();
  // let month: any = date.getMonth() + 1;
  // let day: any = date.getDate();

  // if (day < 10) {
  //   day = "0" + day;
  // }
  // if (month < 10) {
  //   month = "0" + month;
  // }

  // return day + "-" + month + "-" + year + " - ";
  return date.toLocaleString("en-GB");
}
