import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-nucleus-error font-bold"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["id", "value", "placeholder", "maxlength"]
const _hoisted_6 = {
  key: 0,
  class: "text-nucleus-error absolute text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block w-full mb-1 text-black"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " * "))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("textarea", {
        id: _ctx.id,
        class: _normalizeClass(["block w-full border border-nucleus-darkestBlue rounded p-2 bg-nucleus-lightestBlue color-nucleus-darkestBlue focus:outline-none focus:ring-2 focus:ring-nucleus-primaryBlue focus:border-transparent", {
          'border-nucleus-error focus:ring-nucleus-error': _ctx.error,
        }]),
        value: _ctx.value,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false)),
        maxlength: _ctx.maxLength
      }, null, 42, _hoisted_5)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}