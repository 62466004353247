import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-nucleus-darkerBlue p-6" }
const _hoisted_2 = {
  key: 0,
  class: "text-center text-xl p-10 text-nucleus-grey"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-xl mb-6 text-nucleus-grey font-bold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-4 mb-8" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = {
  key: 3,
  class: "text-center"
}
const _hoisted_10 = {
  key: 4,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.status === 'verifying')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.verify.titleVerifying")), 1))
        : _createCommentVNode("", true),
      (_ctx.details && _ctx.status === 'verifyPhone')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("pages.verify.title")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.details.user), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.$t('pages.verify.description', { tenant: _ctx.details.tenant })
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.verify.partialPhoneNumber")) + " ", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.partialPhoneNumber), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.status === 'verifyPhone')
        ? (_openBlock(), _createElementBlock("form", {
            key: 2,
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verifyCodeRemoteUser && _ctx.verifyCodeRemoteUser(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Input, {
              value: _ctx.code,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.code) = $event)),
              "input-type": "number",
              label: _ctx.$t('inputs.verificationCodeBasic.label'),
              id: "code",
              maxLength: _ctx.inputMaxLengths.code,
              placeholder: _ctx.$t('inputs.verificationCodeBasic.placeholder')
            }, null, 8, ["value", "label", "maxLength", "placeholder"]),
            _createVNode(_component_Button, {
              type: "submit",
              name: _ctx.$t('buttons.verify'),
              class: "mb-5"
            }, null, 8, ["name"]),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.verifyRemoteUser())),
              class: "inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition text-sm cursor-pointer"
            }, _toDisplayString(_ctx.$t("pages.verify.resendSMS")), 1)
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.status === 'verifySuccess')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.verify.successful")), 1))
        : _createCommentVNode("", true),
      (_ctx.status === 'alreadyVerified')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("pages.verify.alreadyVerified")), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}