import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bee39b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block leading-none capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "block bg-white p-2 text-nucleus-grey text-center w-full border-b-4 border-white z-10 link",
    to: _ctx.to
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["block icon text-3xl", 'icon-' + _ctx.icon])
      }, null, 2),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.name), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}