
import { Options, Vue } from "vue-class-component";

import { Auth } from "aws-amplify";
import Languages from "@/store/data/languages.json";
import { findLanguage } from "@/utils/languages";

@Options({
  data() {
    return {
      menu: false,
      languages: Languages,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true;
    },
    changeLanguage(language: string) {
      this.$root.$i18n.locale = language;
      this.$store.dispatch("changeLanguage", language);
      this.persistUserLanguage(language);
      this.menu = false;
    },
    async persistUserLanguage(language: string) {
      const user = this.$store.getters.getUserObject;
      if (user) {
        const currentLanguage = user.attributes["custom:language"];
        if (language !== currentLanguage) {
          await Auth.updateUserAttributes(user, {
            ["custom:language"]: language,
          });
          console.log("Updated user language attribute to:", language);
          const updatedUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          await this.$store.dispatch("setUserObject", updatedUser);
        }
      }
    },
    findCurrentLanguage() {
      return findLanguage(this.currentLanguage);
    },
  },
})
export default class LanguageMenu extends Vue {}
