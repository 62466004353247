
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import SelectCountry from "@/components/utils/SelectCountry.vue"; // @ is an alias to /src
import CountryCodesJson from "@/store/data/countryCodes.json"; // @ is an alias to /src
import CountryCodesCommonJson from "@/store/data/countryCodesCommon.json"; // @ is an alias to /src
import Checkbox from "@/components/utils/Checkbox.vue"; // @ is an alias to /src
import ErrorBox from "@/components/utils/ErrorBox.vue";

import parsePhoneNumber from "libphonenumber-js";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    Button,
    ButtonSecondary,
    HeaderShadow,
    Input,
    Select,
    SelectCountry,
    Checkbox,
    ErrorBox,
  },
  data() {
    return {
      countryCodes: CountryCodesJson,
      countryCodesCommon: CountryCodesCommonJson,
      localUser: {
        name: "",
        email: "",
        countryCode: CountryCodesCommonJson[0].dial_code,
        phone: "",
        role: false,
        tenantId:
          this.$store.getters.getUserObject.attributes["custom:tenancy"],
      },
      correctNumber: "",
      error: {
        email: "",
        phone: "",
      },
      loading: false,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  mounted() {
    document.title =
      this.$t("global.defaults.addLocal") +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    addLocalUser() {
      this.loading = true;

      let role = "STAFF";
      if (this.localUser.role) role = "LOCAL_ADMIN";

      const data = {
        name: this.localUser.name,
        email: this.localUser.email,
        phone: this.correctNumber,
        role: role,
        tenantId: this.localUser.tenantId,
      };

      this.$store
        .dispatch("addLocalUser", data)
        .then((response: Record<string, unknown>) => {
          if (response) {
            this.loading = false;
            this.$store.dispatch("changeUpdate", "localUser");
            this.$router.push({ name: "localUsers" });
            console.log("add local admin", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorFixed = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    validateEmail(email: string) {
      const mailformat =
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (this.required(email)) {
        if (!email.match(mailformat)) {
          this.error.email = this.$t("inputs.email.errorInvalid");
          return false;
        } else {
          this.error.email = "";
          return true;
        }
      } else {
        return false;
      }
    },
    validatePhoneNumber(code: any, phone: any) {
      const phoneNumber = parsePhoneNumber(
        code + phone.replace(/^0+/, "").replace(/\s/g, "")
      );

      if (this.required(phone)) {
        if (phoneNumber?.isValid()) {
          this.error.phone = "";
          this.correctNumber = phoneNumber.number;
          return true;
        } else {
          this.error.phone = this.$t("inputs.phone.errorInvalid");
          return false;
        }
      } else {
        return false;
      }
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
  },
})
export default class LocalUsersAdd extends Vue {}
