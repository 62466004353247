
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    themeOpposite() {
      return this.$store.getters.getTheme === "dark" ? "light" : "dark";
    },
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("toggleTheme");
    },
  },
})
export default class Home extends Vue {}
