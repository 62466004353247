import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6 text-nucleus-text" }
const _hoisted_2 = { class: "inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition ml-1 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.heading) + " ", 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.linkText), 1)
  ]))
}