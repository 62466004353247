import axiosBase from "axios";
import { AxiosResponse } from "axios";
import { Commit } from "vuex";
import store from "@/store";
import { isValidLanguage } from "@/utils/languages";
import { IndexesType } from "@aws-amplify/datastore";

const baseUrl = process.env.VUE_APP_API_URL?.endsWith("/")
  ? process.env.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL + "/";

const baseLanguage = process.env.VUE_APP_LANGUAGE;

const axiosUnauthenticated = axiosBase.create({
  baseURL: baseUrl,
});

export interface CommitFunction {
  commit: Commit;
}

export default {
  state: {
    docs: {} as any,
    language: baseLanguage || "en",
    update: "",
  },

  getters: {
    getLanguage: (state: any): string => {
      return state.language;
    },
  },

  mutations: {
    // eslint-disable-next-line
    SET_LANGUAGE(state: any, language: string) {
      state.language = language;
      localStorage.language = language;
    },
    // eslint-disable-next-line
    SET_UPDATE(state: any, update: string) {
      state.update = update;
    },
  },

  actions: {
    async getDocs(_: any, key: string): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosUnauthenticated.get("docs/" + key);
        // console.log(response.data);
        return response.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    // eslint-disable-next-line
    initLanguage({ commit }: CommitFunction) {
      const user = store.getters.getUserObject;
      const userLanguage = user?.attributes["custom:language"];

      const cachedLanguage = localStorage.language
        ? localStorage.language
        : undefined;

      const browserLanguage = navigator.language
        ? navigator.language.substring(0, 2)
        : undefined;

      let language;
      if (isValidLanguage(userLanguage)) {
        // Language selection persisted against Cognito user
        console.log("Using user language", userLanguage);
        language = userLanguage;
      } else if (isValidLanguage(cachedLanguage)) {
        // Language selection persisted in browser local storage
        console.log("Using cached language", cachedLanguage);
        language = cachedLanguage;
      } else if (isValidLanguage(browserLanguage)) {
        // Language based on the browser locale
        console.log("Using browser language", browserLanguage);
        language = browserLanguage;
      } else if (isValidLanguage(baseLanguage)) {
        // Default language defined in the Vue App environment variables
        console.log("Using base language", baseLanguage);
        language = baseLanguage;
      } else {
        // Finally fallback to English
        console.log("Using default language", "en");
        language = "en";
      }
      commit("SET_LANGUAGE", language);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    changeLanguage(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      language: string
    ) {
      commit("SET_LANGUAGE", language);
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    changeUpdate(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      update: string
    ) {
      commit("SET_UPDATE", update);
    },
  },
};
