
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src
import Textarea from "@/components/utils/Textarea.vue"; // @ is an alias to /src
import ListItemBasic from "@/components/utils/ListItemBasic.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    Button,
    ButtonSecondary,
    HeaderShadow,
    Input,
    Textarea,
    ListItemBasic,
    Select,
    LoadingScreen,
    ErrorBox,
  },
  data() {
    return {
      session: {},
      loaded: false,
      loading: false,
      operatingRooms: null,
      loadingMessage: this.$t("global.defaults.loadingSession"),
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  beforeMount() {
    this.getSession();
  },
  mounted() {
    document.title =
      this.$t("global.defaults.editSession") +
      " - " +
      this.$t("global.defaults.browserTitle");
    this.getSessionOptions();
  },
  methods: {
    getSession() {
      this.$store
        .dispatch("getSession", this.$route.params.sessionId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.session = response.data;
            this.loaded = true;
            if (this.session.state === "STARTED") this.startingStopping = false;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorFixed = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },

    editSession() {
      this.loading = true;

      this.session.roomId = Number(this.session.roomId);

      let roomName = "";

      if (this.operatingRooms) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        roomName = this.operatingRooms.filter((room: any) => {
          return room.id === this.session.roomId;
        })[0].name;
      }

      const data = {
        name: this.session.name,
        roomId: this.session.roomId,
        roomName: roomName,
        notes: this.session.notes,
      };

      console.log(data);

      this.$store
        .dispatch("editSession", { sessionId: this.session.id, data: data })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response) {
            this.loading = false;
            this.$store.dispatch("changeUpdate", "session");
            this.$router.push({
              name: "sessionsDetail",
              params: { sessionId: this.$route.params.sessionId },
            });
            console.log("edit session", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorFixed = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },

    getSessionOptions() {
      this.$store
        .dispatch(
          "getSessionOptions",
          this.$store.getters.getUserObject.attributes["custom:tenancy"]
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.data) {
            this.operatingRooms = response.data.operatingRooms;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorFixed = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
  },
})
export default class SessionsAdd extends Vue {}
