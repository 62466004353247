import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabLink = _resolveComponent("TabLink")!
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_component_TabLink, {
        to: "/local-admin/sessions",
        name: _ctx.$t('global.defaults.session', 2),
        icon: "session"
      }, null, 8, ["name"]),
      (!_ctx.staff)
        ? (_openBlock(), _createBlock(_component_TabLink, {
            key: 0,
            to: "/local-admin/local-users",
            name: _ctx.$t('global.defaults.localUser', 2),
            icon: "users"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createVNode(_component_TabLink, {
        to: "/local-admin/remote-users",
        name: _ctx.$t('global.defaults.remoteUser', 2),
        icon: "remote-users"
      }, null, 8, ["name"]),
      (!_ctx.staff)
        ? (_openBlock(), _createBlock(_component_TabLink, {
            key: 1,
            to: "/local-admin/usage",
            name: _ctx.$t('global.defaults.usage'),
            icon: "usage"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_router_view)
  ]))
}