import { Commit } from "vuex";
import axios from "@/utils/axiosInstance";
import { AxiosResponse } from "axios";

import { Tenancy, TenancyUsage } from "../types/tenancy";
import { SessionOptions } from "../types/sessionOptions";

export interface CommitFunction {
  commit: Commit;
}

export interface EditTenancy {
  tenancyId: string;
  data: unknown;
}

export interface AddCredits {
  tenancyId: string;
  credits: string;
}

export default {
  state: {
    tenancy: {} as Tenancy,
    sessionOptions: {} as SessionOptions,
  },

  getters: {
    getActiveTenancy: (tenancy: Tenancy): Tenancy => {
      return tenancy;
    },
    getSessionOptions: (sessionOptions: SessionOptions): SessionOptions => {
      console.log("reggergr", sessionOptions);
      // console.log("reggergr", sessionOptions);
      return sessionOptions;
    },
  },
  mutations: {
    SET_ACTIVE_TENANCY(state: Record<string, unknown>, data: Tenancy): void {
      state.tenancy = data;
    },
    SET_SESSION_OPTIONS(
      state: Record<string, unknown>,
      data: SessionOptions
    ): void {
      state.sessionOptions = data;
    },
    SET_TENANCY_USAGE(
      state: Record<string, unknown>,
      data: TenancyUsage
    ): void {
      state.tenancyUsage = data;
    },
  },
  actions: {
    async getTenancies(): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get("tenancy");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getTenancy(
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get("tenancy/" + tenancyId);
        commit("SET_ACTIVE_TENANCY", response.data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getTenancyUsage({
      commit,
    }: CommitFunction): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get("tenancy/usage");
        commit("SET_TENANCY_USAGE", response.data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async addTenancy(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      data: unknown
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("tenancy", data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async editTenancy(
      _: any,
      { tenancyId, data }: EditTenancy
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.put("tenancy/" + tenancyId, data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async deleteTenancy(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.delete("tenancy/" + tenancyId);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async disableTenancy(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("tenancy/" + tenancyId + "/disable");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async enableTenancy(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("tenancy/" + tenancyId + "/enable");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getTenancyConnectionStatus(
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get(
          `tenancy/${tenancyId}/connectionstatus`
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async generateClientCertificate(
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post(`tenancy/${tenancyId}/generatecert`);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async reserveMediaServerIps(
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post(`tenancy/${tenancyId}/reserveips`);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async releaseMediaServerIps(
      { commit }: CommitFunction,
      tenancyId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post(`tenancy/${tenancyId}/releaseips`);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async addUsageCredits(
      { commit }: CommitFunction,
      { tenancyId, credits }: AddCredits
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post(`tenancy/${tenancyId}/credit`, {
          credits: parseInt(credits),
        });
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getSessionOptions(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      tenantId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get(
          "tenancy/" + tenantId + "/sessionoptions"
        );
        commit("SET_SESSION_OPTIONS", response.data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
  },
};
