import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen grid content-between" }
const _hoisted_2 = { class: "bg-nucleus-lightestBlue dark:bg-nucleus-darkestBlue flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Header),
      _createElementVNode("main", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    (_ctx.checkAccessPage(_ctx.currentRouteName))
      ? (_openBlock(), _createBlock(_component_Footer, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}