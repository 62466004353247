/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isAccessPage(routeName: string) {
  if (
    routeName === "Login" ||
    routeName === "verify" ||
    routeName === "RemoteClientAccessSession"
  ) {
    return true;
  } else {
    return false;
  }
}
