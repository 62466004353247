
import { Options, Vue } from "vue-class-component";
import AddButton from "@/components/utils/AddButton.vue";
import ListItem from "@/components/utils/ListItem.vue";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    AddButton,
    ListItem,
    LoadingScreen,
    ErrorBox,
  },
  data() {
    return {
      tenancies: null,
      loaded: false,
      loadingMessage: this.$t("global.defaults.loading"),
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      usage: null,
    };
  },
  created() {
    this.getTenancies();
  },
  updated() {
    if (this.$store.state.global.update === "tenancy") this.getTenancies();
  },
  methods: {
    getTenancies() {
      this.$store
        .dispatch("getTenancies")
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.$store.dispatch("changeUpdate", "");
            this.tenancies = response.data;
            this.loaded = true;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    getAllowance(tenancy: Record<string, number>) {
      let remaining =
        tenancy.creditsTotal - tenancy.creditsUsed - tenancy.creditsExpired;
      let remainingText;
      if (remaining === 0) {
        remainingText = this.$t("pages.usage.0Hours");
      } else if (remaining > -1 && remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebt");
      } else if (remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebtAmount", {
          remaining: Math.floor(Math.abs(remaining)),
        });
      } else if (remaining > 0 && remaining < 1) {
        remainingText = this.$t("pages.usage.lessThan1Remaining");
      } else {
        remainingText = this.$t("pages.usage.remaining", {
          remaining: Math.floor(remaining),
        });
      }

      let usedText;
      if (tenancy.creditsUsed > 0 && tenancy.creditsUsed < 1) {
        usedText = this.$t("pages.usage.lessThan1Used", {
          remaining: tenancy.creditsTotal,
        });
      } else {
        usedText = this.$t("pages.usage.used", {
          used: Math.floor(tenancy.creditsUsed),
          total: tenancy.creditsTotal,
        });
      }

      return {
        remaining: remainingText,
        used: usedText,
        licenseExpiry: tenancy.licenseExpiry,
      };
    },
  },
})
export default class ServiceAdminTenancies extends Vue {}
