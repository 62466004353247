
import { mapAwsUserStatus } from "@/utils/awsMappings";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    icon: String,
    room: String,
    status: String,
    notes: String,
  },
  methods: {
    getStatusColor() {
      const text = this.status.toLowerCase();
      switch (text) {
        case "active":
        case "started":
        case "enabled":
        case "joined":
        case "verified":
        case "confirmed":
          return "text-nucleus-primaryBlue font-bold";
        case "pending":
          return "text-nucleus-text";
        case "failed":
        case "declined":
        case "reset_required":
        case "force_password_change":
          return "text-nucleus-error font-bold";
        default:
          return "text-nucleus-text";
      }
    },
    getStatusName(name: string) {
      return this.$t(mapAwsUserStatus(name));
    },
  },
})
export default class ListItem extends Vue {}
