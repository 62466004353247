import RootState from "@/store";
import axios from "@/utils/axiosInstance";
import axiosBase from "axios";
import { AxiosResponse } from "axios";
import { Commit } from "vuex";

const baseUrl = process.env.VUE_APP_API_URL?.endsWith("/")
  ? process.env.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL + "/";

const axiosUnauthenticated = axiosBase.create({
  baseURL: baseUrl,
});

export interface CommitFunction {
  commit: Commit;
}

export interface VerifyUser {
  token: string;
  userId: string;
}
export interface EditUser {
  userId: string;
  data: unknown;
}
export interface VerifyUserPhone {
  token: string;
  userId: string;
  code: string;
}

export default {
  state: {
    remoteUsers: {} as any,
  },
  mutations: {
    SET_REMOTE_USERS(state: Record<string, unknown>, data: any): void {
      state.remoteUsers = data;
    },
  },
  actions: {
    async getLocalUsers(): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get(
          "user?tenantId=" +
            RootState.getters.getUserObject.attributes["custom:tenancy"]
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async getLocalUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get("user/" + userId);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async addLocalUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      data: unknown
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("user", data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async editLocalUser(
      _: any,
      { userId, data }: EditUser
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.put("user/" + userId, data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async reinviteLocalUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("user/" + userId + "/reinvite");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async deleteLocalUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.delete("user/" + userId);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async getRemoteUsers({
      commit,
    }: CommitFunction): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get(
          "remoteuser?tenantId=" +
            RootState.getters.getUserObject.attributes["custom:tenancy"]
        );
        commit("SET_REMOTE_USERS", response.data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getRemoteUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.get("remoteuser/" + userId);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async addRemoteUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      data: unknown
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("remoteuser", data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async editRemoteUser(
      _: any,
      { userId, data }: EditUser
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.put("remoteuser/" + userId, data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async deleteRemoteUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.delete("remoteuser/" + userId);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async inviteRemoteUser(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      userId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axios.post("remoteuser/" + userId + "/invite");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getRemoteUserDetails(
      _: any,
      { token, userId }: VerifyUser
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosUnauthenticated.get(
          "verification/invite?token=" + token + "&userId=" + userId
        );
        console.log(response);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async sendCodeRemoteUser(
      _: any,
      { token, userId }: VerifyUser
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosUnauthenticated.post(
          "verification/sendcode?token=" + token + "&userId=" + userId
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async verifyCodeRemoteUser(
      _: any,
      { token, userId, code }: VerifyUserPhone
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosUnauthenticated.post(
          "verification/verifycode?token=" +
            token +
            "&userId=" +
            userId +
            "&code=" +
            code
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
  },
};
