import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00cd71d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block bg-nucleus-white hover:bg-nucleus-listBgHover hover:border-nucleus-listBgHover p-3 text-nucleus-text rounded transition flex border border-nucleus-white mb-1 link" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "text-nucleus-grey" }
const _hoisted_4 = { class: "text-nucleus-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["icon text-3xl leading-none p-2 text-nucleus-text mr-6 flex self-center transition bg-nucleus-iconBg rounded-full", 'icon-' + _ctx.icon])
    }, null, 2),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.room), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["text-sm mt-1", _ctx.getStatusColor()])
      }, _toDisplayString(_ctx.getStatusName(_ctx.status)), 3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.notes), 1)
    ])
  ]))
}