
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";
import Button from "@/components/utils/Button.vue";
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import { Auth } from "aws-amplify";
import router from "@/router";
import TextLink from "@/components/utils/TextLink.vue";
import { getLocalisedError } from "@/utils/apiUtils";
import { getDefaultRegion } from "@/utils/languages";

@Options({
  components: {
    HeaderShadow,
    Logo,
    Button,
    ButtonSecondary,
    ErrorBox,
    TextLink,
  },
  data() {
    return {
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      element: HTMLElement,
      activateButtons: true,
      loading: false,
      eulaDoc: null,
      privacyDoc: null,
      user: null,
      isStatic: false,
      unwatch: undefined,
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      (state: any, getters: any) => getters.getLanguage,
      (newValue: string, _oldValue: string) => {
        console.log(`Language changed from ${_oldValue} to ${newValue}`);
        this.loadDocuments();
      }
    );
  },
  mounted() {
    //this.hasScrolled();

    this.loadDocuments();

    this.user = this.$store.getters.getUserObject;

    if (this.$route.query.static) {
      this.isStatic = true;
    } else {
      if (!this.user || this.user.attributes["custom:agreement"] === "1") {
        router.push({ name: "Login" });
      }
    }
  },
  beforeUnmount() {
    this.unwatch();
  },
  methods: {
    /*
    hasScrolled() {
      this.element = document.getElementById("scrollBox");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.element.addEventListener("scroll", (event: any) => {
        var element = event.target;
        if (
          element.offsetHeight + Math.ceil(element.scrollTop) >=
          element.scrollHeight
        ) {
          this.activateButtons = true;
        }
      });
    },
    */
    loadDocuments() {
      let language = this.$route.query.lang;
      let region = this.$route.query.region;

      if (!language) {
        // Get language from state
        language = this.$store.getters.getLanguage;
      }
      if (language && !region) {
        // Get default region for language
        region = getDefaultRegion(language);
      }

      console.log(
        `Loading EULA and Privacy Policy for region ${region} and language ${language}`
      );

      // TODO Refactor these!
      this.getPrivacy(region, language);
      this.getEula(region, language);
    },
    async getPrivacy(region?: string, language?: string) {
      this.privacyDoc = await this.getDoc(
        "PrivacyPolicy.html",
        region,
        language
      );
    },
    async getEula(region?: string, language?: string) {
      this.eulaDoc = await this.getDoc("EULA.html", region, language);
    },
    async getDoc(
      name: string,
      region?: string,
      language?: string
    ): Promise<string | null> {
      const key = this.getDocumentKey(name, region, language);
      try {
        return await this.$store.dispatch("getDocs", key);
      } catch (error: any) {
        if (error.status === 404 && region && language) {
          // Try to get the default document for the region
          return await this.getDoc(name, region);
        } else if (error.status === 404 && (region || language)) {
          // Try to get the default document for any region
          return await this.getDoc(name);
        } else {
          // this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        }
      }
      return null;
    },
    getDocumentKey(name: string, region?: string, language?: string) {
      const splits = name.split(".", 2);
      let key = "";
      if (region) {
        key += `${region.toLowerCase()}_`;
      }
      key += splits[0];
      if (language) {
        key += `-${language.toLowerCase()}`;
      }
      if (splits.length > 0) {
        key += `.${splits[1]}`;
      }
      // Returned format region_filename-language.extension
      return key;
    },
    async accept() {
      this.loading = true;
      try {
        const updateUser = await Auth.updateUserAttributes(this.user, {
          ["custom:agreement"]: "1",
        });

        if (updateUser) {
          router.go(0);
        }
      } catch (error: any) {
        this.loading = false;
        this.errorFixed = true;
        this.errorHeading = "";
        this.errorContent = error.data?.message;
        console.log("ERROR", error);
      }
    },
    // async decline() {
    //   this.loading = true;
    //   try {
    //     const updateUser = await Auth.updateUserAttributes(this.user, {
    //       ["custom:agreement"]: "0",
    //     });

    //     if (updateUser) {
    //       router.go(0);
    //     }
    //   } catch (error: any) {
    //     this.loading = false;
    //     this.errorFixed = true;
    //     this.errorHeading = "";
    //     this.errorContent = error.data?.message;
    //     console.log("ERROR", error);
    //   }
    // },
    async logout() {
      try {
        await Auth.signOut();
        this.removeAccess();
        router.push({ name: "Login" });
      } catch (err) {
        console.log("ERROR", err);
      }
    },
    removeAccess() {
      this.$store.dispatch("setUserObject", null);
      this.$store.dispatch("setRoles", []);
    },
  },
})
export default class EulaPrivacyPolicy extends Vue {}
