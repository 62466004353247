
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    to: String,
    name: String,
    icon: String,
  },
})
export default class TabLink extends Vue {}
