import { Commit } from "vuex";

import { UserAuth } from "../types/userAuth";

export interface CommitFunction {
  commit: Commit;
}

export default {
  state: {
    userAuth: {} as UserAuth,
  },

  getters: {
    getUserObject: (userAuth: UserAuth): UserAuth["object"] => {
      return userAuth.object;
    },
    getServiceAdmin: (userAuth: UserAuth): boolean => {
      return userAuth.serviceAdmin;
    },
    getLocalAdmin: (userAuth: UserAuth): boolean => {
      return userAuth.localAdmin;
    },
    getStaff: (userAuth: UserAuth): boolean => {
      return userAuth.staff;
    },
  },

  mutations: {
    SET_OBJECT(userAuth: UserAuth, object: UserAuth["object"]): void {
      userAuth.object = object;
    },
    SET_SERVICE_ROLE(userAuth: UserAuth, boolean: boolean): void {
      userAuth.serviceAdmin = boolean;
    },
    SET_LOCAL_ROLE(userAuth: UserAuth, boolean: boolean): void {
      userAuth.localAdmin = boolean;
    },
    SET_STAFF_ROLE(userAuth: UserAuth, boolean: boolean): void {
      userAuth.staff = boolean;
    },
  },

  actions: {
    setUserObject(
      { commit }: CommitFunction,
      object: UserAuth["object"]
    ): void {
      commit("SET_OBJECT", object);
    },
    setRoles({ commit }: CommitFunction, roles: []): void {
      if (!roles) {
        commit("SET_SERVICE_ROLE", false);
        commit("SET_LOCAL_ROLE", false);
      }
      roles.forEach((role) => {
        if (role === "serviceadmins") {
          commit("SET_SERVICE_ROLE", true);
        }
        if (role === "localadmins") {
          commit("SET_LOCAL_ROLE", true);
        }
        if (role === "staff") {
          commit("SET_STAFF_ROLE", true);
        }
      });
    },
  },
};
