import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["label"]
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block w-full mb-1"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("select", {
        id: _ctx.id,
        class: "block w-full border border-nucleus-darkestBlue rounded p-2 bg-nucleus-lightestBlue color-nucleus-darkestBlue",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsCommon, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: option.dial_code
          }, _toDisplayString(option.flag) + " " + _toDisplayString(option.dial_code) + " " + _toDisplayString(option.name[this.$root.$i18n.locale] || option.name["en"]), 9, _hoisted_5))
        }), 128)),
        _createElementVNode("optgroup", {
          label: _ctx.$t('global.defaults.allCountriesRegions')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: option.dial_code
            }, _toDisplayString(option.flag) + " " + _toDisplayString(option.dial_code) + " " + _toDisplayString(option.name[this.$root.$i18n.locale] || option.name["en"]), 9, _hoisted_7))
          }), 128))
        ], 8, _hoisted_6)
      ], 40, _hoisted_4), [
        [_vModelSelect, _ctx.value]
      ])
    ])
  ]))
}