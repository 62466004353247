
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import TextLink from "@/components/utils/TextLink.vue";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderShadow,
    Logo,
    ErrorBox,
    TextLink,
  },
  data() {
    return {
      errorHeading: "",
      errorContent: "",
      doc: null,
    };
  },
  mounted() {
    this.getChangeLog();
  },
  methods: {
    getChangeLog() {
      this.$store
        .dispatch("getDocs", "ChangeLog.html")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          this.doc = response;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
  },
})
export default class ChangeLog extends Vue {}
