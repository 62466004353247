import Languages from "@/store/data/languages.json";

export type Language = {
  id: string;
  locale: string;
  name: string;
  flag: string;
  defaultRegion: string;
};

export function findLanguage(locale: string): Language | undefined {
  return Languages.find((language: Language) => language.locale === locale);
}

export function isValidLanguage(locale: string | undefined): boolean {
  return locale !== undefined && findLanguage(locale) !== undefined;
}

export function getDefaultRegion(locale: string): string | undefined {
  return findLanguage(locale)?.defaultRegion;
}
