
import { Options, Vue } from "vue-class-component";
import { isAccessPage } from "@/utils/isAccessPage";

@Options({
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    checkAccessPage(route: string) {
      return isAccessPage(route);
    },
  },
})
export default class HeaderShadow extends Vue {}
