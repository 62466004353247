
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";
import Input from "@/components/utils/Input.vue";
import Button from "@/components/utils/Button.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderShadow,
    Logo,
    Input,
    Button,
    ErrorBox,
  },
  data() {
    return {
      status: "verifying",
      code: "",
      details: {},
      partialPhoneNumber: "",
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  mounted() {
    this.getRemoteUserDetails();
  },
  methods: {
    getRemoteUserDetails() {
      const token = this.$route.query.token;
      const userId = this.$route.query.userId;

      console.log(token, userId);

      this.$store
        .dispatch("getRemoteUserDetails", { token, userId })
        .then((response: Record<string, any>) => {
          if (response) {
            this.details = response.data;
            this.verifyRemoteUser();

            this.errorHeading = "";
            this.errorContent = "";
            console.log("Details", response.data);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    verifyRemoteUser() {
      const token = this.$route.query.token;
      const userId = this.$route.query.userId;

      this.$store
        .dispatch("sendCodeRemoteUser", { token, userId })
        .then((response: Record<string, any>) => {
          if (response) {
            this.status = "verifyPhone";
            this.partialPhoneNumber = response.data.partialPhoneNumber;

            this.errorHeading = "";
            this.errorContent = "";
            console.log("Send code", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    verifyCodeRemoteUser() {
      const token = this.$route.query.token;
      const userId = this.$route.query.userId;
      const code = this.code;

      this.$store
        .dispatch("verifyCodeRemoteUser", { token, userId, code })
        .then((response: Record<string, unknown>) => {
          if (response) {
            this.status = "verifySuccess";
            this.errorHeading = "";
            this.errorContent = "";
            console.log("Verify code", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
  },
})
export default class Home extends Vue {}
