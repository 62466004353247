import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-4 mt-6 z-20 text-nucleus-white bg-nucleus-error shadow-xl flex justify-between items-center", { 'fixed -top-6 mt-0 left-0 w-full': _ctx.fixed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "p-2 bg-nucleus-white text-nucleus-error icon-close rounded-full font-bold mr-4 h-8 w-8",
        title: _ctx.$t('titles.closeError')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, [
        (_ctx.heading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.heading), 1))
          : _createCommentVNode("", true),
        (_ctx.content)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass({
            'font-bold': !_ctx.heading,
            'text-sm': _ctx.heading,
          })
            }, _toDisplayString(_ctx.content), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeError())),
      class: "w-28 underline font-bold cursor-pointer p-2 justify-self-end"
    }, _toDisplayString(_ctx.$t("global.defaults.closeError")), 1)
  ], 2))
}