import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "checked"]
const _hoisted_3 = { class: "ml-2 h-5 align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.id,
    class: "flex h-5 items-center mb-6"
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      type: "checkbox",
      value: _ctx.value,
      checked: _ctx.value,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args))),
      class: "h-5 w-5"
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1)
  ], 8, _hoisted_1))
}