
import { Options, Vue } from "vue-class-component";
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import { getPrettyDate } from "@/utils/prettyDate";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    InfoItem,
    LoadingScreen,
    ErrorBox,
  },
  data() {
    return {
      usage: {
        license: "",
        licenseExpiry: "",
        creditsTotal: 0,
        creditsUsed: 0,
        creditsExpired: 0,
      },
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      loadingMessage: this.$t("global.defaults.loading"),
      loaded: false,
    };
  },
  mounted() {
    document.title =
      this.$t("global.defaults.usage") +
      " - " +
      this.$t("global.defaults.browserTitle");
    this.getUsage();
  },
  methods: {
    getUsage() {
      this.$store
        .dispatch("getTenancyUsage")
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            // this.$router.replace({ name: "usage", params: {} });
            this.usage = response.data;
            this.loaded = true;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.log(error, typeof error);
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    getHoursLeft() {
      let remaining =
        this.usage.creditsTotal -
        this.usage.creditsUsed -
        this.usage.creditsExpired;
      let remainingText;
      if (remaining === 0) {
        remainingText = this.$t("pages.usage.0Hours");
      } else if (remaining > -1 && remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebt");
      } else if (remaining < 0) {
        remainingText = this.$t("pages.usage.0HoursDebtAmount", {
          remaining: Math.floor(Math.abs(remaining)),
        });
      } else if (remaining > 0 && remaining < 1) {
        remainingText = this.$t("pages.usage.lessThan1Remaining");
      } else {
        remainingText = this.$t("pages.usage.remaining", {
          remaining: Math.floor(remaining),
        });
      }
      return remainingText;
    },
    getHoursUsed() {
      let usedText;
      if (this.usage.creditsUsed > 0 && this.usage.creditsUsed < 1) {
        usedText = this.$t("pages.usage.lessThan1Used", {
          remaining: this.usage.creditsTotal,
        });
      } else {
        usedText = this.$t("pages.usage.used", {
          used: Math.floor(this.usage.creditsUsed),
          total: this.usage.creditsTotal,
        });
      }
      return usedText;
    },
    getDate(date: Date) {
      return getPrettyDate(date);
    },
  },
})
export default class Usage extends Vue {}
