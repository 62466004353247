import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative mr-5" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "fixed z-20 top-header right-0 text-right"
}
const _hoisted_4 = { class: "bg-nucleus-white shadow-lg p-4" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "border-b pb-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu())),
        class: _normalizeClass(["icon-user align-middle text-3xl dark:text-white cursor-pointer leading-none", 
          _ctx.currentPage === 'Login' ? 'text-nucleus-white' : 'text-nucleus-grey'
        ]),
        title: _ctx.$t('titles.userMenu')
      }, null, 10, _hoisted_2),
      (_ctx.menu)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user.attributes.name), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.user.attributes.email), 1),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout())),
                class: "py-2 cursor-pointer"
              }, _toDisplayString(_ctx.$t("global.defaults.logOut")), 1)
            ])
          ])), [
            [_directive_click_away, _ctx.toggleMenu]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}