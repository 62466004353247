
import { Options, Vue } from "vue-class-component";
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import HeaderMiniBasic from "@/components/global/HeaderMiniBasic.vue"; // @ is an alias to /src
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import AddButtonBasic from "@/components/utils/AddButtonBasic.vue"; // @ is an alias to /src
import ListItemBasic from "@/components/utils/ListItemBasic.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import ButtonRed from "@/components/utils/ButtonRed.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ModalConfirmation from "@/components/global/ModalConfirmation.vue";
import ModalDeleteCheck from "@/components/global/ModalDeleteCheck.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import { mapAwsUserStatus } from "@/utils/awsMappings";
import { getPrettyDate } from "@/utils/prettyDate";
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderMini,
    HeaderMiniBasic,
    HeaderShadow,
    InfoItem,
    LoadingScreen,
    ListItemBasic,
    AddButtonBasic,
    Button,
    ButtonSecondary,
    ButtonRed,
    ModalConfirmation,
    ModalDeleteCheck,
    ErrorBox,
    Input,
  },
  data() {
    return {
      session: {},
      loaded: false,
      showParticipants: false,
      users: null,
      usersOriginal: null,
      startingStopping: false,
      interval: null,
      timeout: 10000,
      sessionStatusCount: 0,
      deleteCheck: false,
      deleteCheckUser: false,
      loadingMessage: this.$t("global.defaults.loadingSession"),
      selectedParticipant: {},
      showParticipantDetails: false,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      sessionTimeout: null,
      nameFilter: "",
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  computed: {
    sessionOptions() {
      return this.$store.state.tenancy.sessionOptions;
    },
    remoteUsers() {
      return this.$store.state.user.remoteUsers;
    },
  },
  beforeDestroy() {
    clearTimeout(this.sessionTimeout);
  },
  beforeRouteLeave() {
    clearTimeout(this.sessionTimeout);
  },
  beforeMount() {
    this.getSession();
  },
  mounted() {
    document.title =
      this.$t("global.defaults.session", 1) +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    getIconColour() {
      const text = this.session.state.toLowerCase();
      switch (text) {
        case "started":
          return "text-white bg-nucleus-primaryBlue";
        default:
          return "text-nucleus-text bg-nucleus-iconBg";
      }
    },
    mapState(state: string) {
      return this.$t(mapAwsUserStatus(state));
    },
    getDate(date: Date) {
      return getPrettyDate(date);
    },
    getParticipantNotes(user: Record<string, unknown>) {
      let state = user.state;
      switch (state) {
        case "PENDING":
          return this.$t("pages.session.invitePending");

        case "SENT":
          return this.$t("pages.session.inviteSent");

        case "JOINED":
          return this.$t("pages.session.inviteJoined");

        case "DECLINED":
          if (user.reason) {
            return (
              this.$t("pages.session.inviteDeclined") + " - " + user.reason
            );
          }
          return this.$t("pages.session.inviteDeclined");

        default:
          return "";
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getParticipantDetails(index: any) {
      this.selectedParticipant = this.session.invitees[index];
      this.showParticipantDetails = true;
    },
    getSession() {
      this.$store
        .dispatch("getSession", this.$route.params.sessionId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.session = response.data;

            if (!this.sessionOptions.maxRemoteUsers) this.getSessionOptions();
            this.loaded = true;
            if (this.session.state === "STARTED") this.startingStopping = false;
            if (this.session.state === "STARTING") {
              this.startingStopping = true;
              this.checkSessionStatus();
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    startSession() {
      if (this.session.state === "PENDING") {
        this.startingStopping = true;
        this.$store
          .dispatch("startSession", this.session.id)
          .then((response: Record<string, unknown>) => {
            if (response) {
              console.log(response);
              this.checkSessionStatus();
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            this.loaded = true;
            this.startingStopping = false;
            this.errorHeading = "";
            this.errorContent = getLocalisedError(error);
          });
      }
    },
    endSession() {
      this.startingStopping = true;
      this.$store
        .dispatch("endSession", this.session.id)
        .then((response: Record<string, unknown>) => {
          if (response) {
            console.log(response);
            this.startingStopping = false;
            this.session.state = "FINISHED";
            this.getSession();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.startingStopping = false;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    checkSessionStatus() {
      if (this.sessionTimeout) clearTimeout(this.sessionTimeout);
      // console.log("count", this.sessionStatusCount);
      // console.log("interval time", this.timeout);

      if (this.session.state === "STARTED") {
        this.startingStopping = false;
        this.sessionStatusCount = 0;
      } else {
        if (this.session.state === "STARTING") this.timeout = 35000;
        if (this.session.state === "STARTING" && this.sessionStatusCount > 1)
          this.timeout = 5000;

        this.sessionTimeout = setTimeout(() => {
          this.sessionStatusCount++;
          this.getSession();
          if (this.session.state === "PENDING") this.checkSessionStatus();
        }, this.timeout);
      }
    },
    toggleParticipants() {
      this.showParticipants = this.showParticipants ? false : true;
      if (this.showParticipants) {
        this.formatUsers();
      } else {
        this.clearInput();
      }
    },
    getSessionOptions() {
      this.$store
        .dispatch(
          "getSessionOptions",
          this.$store.getters.getUserObject.attributes["custom:tenancy"]
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.data) {
            // this.maxRemoteUsers = response.data.maxRemoteUsers;
            // console.log(response.data);
            // console.log("Session options", this.sessionOptions);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    getUsers() {
      this.loaded = false;
      this.loadingMessage = this.$t("global.defaults.loading");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$store
        .dispatch("getRemoteUsers")
        .then((response: any) => {
          if (response) {
            this.loaded = true;
            this.formatUsers();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    formatUsers() {
      if (this.remoteUsers[0]) {
        var userIds: any[] = [];

        this.session.invitees.forEach(function (user: any) {
          userIds.push(user.userId);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.users = this.remoteUsers.filter((user: any) => {
          return !userIds.includes(user.userId);
        });

        this.users = this.orderByName(this.users);
        this.usersOriginal = this.users;
      } else {
        this.getUsers();
      }
    },
    addParticipant(user: Record<string, unknown>) {
      if (user.status === "VERIFIED") {
        this.loaded = false;
        this.loadingMessage = this.$t("pages.session.addingParticipant");
        const data = {
          userId: user.userId,
        };

        this.$store
          .dispatch("addUserToSession", {
            sessionId: this.session.id,
            data: data,
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            if (response) {
              this.toggleParticipants();
              this.getSession();
              // console.log("add user to session", response);
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            this.toggleParticipants();
            this.loaded = true;
            this.errorHeading = "";
            this.errorContent = getLocalisedError(error);
          });
      }
    },
    removeParticipant(user: Record<string, unknown>) {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.session.removingParticipant");
      const data = {
        userId: user.userId,
      };

      this.$store
        .dispatch("removeUserFromSession", {
          sessionId: this.session.id,
          data: data,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response) {
            this.showParticipantDetails = false;
            this.getSession();
            // console.log("remove user from session", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.showParticipantDetails = false;
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },

    edit() {
      this.$router.push({
        name: "sessionsEdit",
        params: { sessionId: this.$route.params.sessionId },
      });
    },
    deleteSession() {
      this.loaded = false;
      this.loadingMessage = this.$t("global.defaults.deletingSession");
      this.$store
        .dispatch("deleteSession", this.$route.params.sessionId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            console.log(response.data);
            this.$store.dispatch("changeUpdate", "session");
            this.$router.push({ name: "sessions" });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    reinviteParticipant(user: Record<string, unknown>) {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.session.reInviting");

      const data = {
        userId: user.userId,
      };

      this.$store
        .dispatch("reinviteUserToSession", {
          sessionId: this.session.id,
          data: data,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response) {
            this.showParticipantDetails = false;
            this.getSession();
            // console.log("reinvite user to session", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.showParticipantDetails = false;
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    toLowerCase(text: string) {
      return text.toLowerCase();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orderByName(object: Array<any>) {
      return object.sort((a, b) => a.name.localeCompare(b.name));
    },
    filterByName(name: string) {
      console.log(name);
      this.users = this.usersOriginal.filter((user: any) =>
        user.name.toLowerCase().includes(name.toLowerCase())
      );
    },
    clearInput() {
      this.nameFilter = "";
      this.filterByName(this.nameFilter);
    },
  },
})
export default class SessionsDetail extends Vue {}
