
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: String,
    list: Array,
  },
})
export default class InfoItem extends Vue {}
