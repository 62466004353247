import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-25px" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_LanguageMenu = _resolveComponent("LanguageMenu")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["flex justify-between items-center dark:bg-nucleus-darkBlue", {
      'bg-black': _ctx.checkAccessPage(_ctx.currentRouteName),
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require(`@/assets/` + _ctx.logo),
        alt: "Sony Logo Black",
        class: "w-auto h-25px"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showUserMenu)
        ? (_openBlock(), _createBlock(_component_UserMenu, {
            key: 0,
            currentPage: _ctx.currentRouteName
          }, null, 8, ["currentPage"]))
        : _createCommentVNode("", true),
      _createVNode(_component_LanguageMenu)
    ])
  ], 2))
}