import axios, { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";

const baseUrl = process.env.VUE_APP_API_URL?.endsWith("/")
  ? process.env.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL + "/";

const instance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken();
    const jwtToken = accessToken.getJwtToken();

    config.headers.Authorization = jwtToken;

    return config;
  },
  function (error: unknown) {
    return Promise.reject(error);
  }
);

export default instance;
