
import { Options, Vue } from "vue-class-component";
import DarkToggle from "@/components/global/DarkToggle.vue"; // @ is an alias to /src

@Options({
  props: {
    name: String,
    to: String,
  },
  emits: ["deleteTenancyCheck", "goBack"],
  components: {
    DarkToggle,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    logo() {
      return "logo_black.svg";
    },
    pageName() {
      return this.$route.name;
    },
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true;
    },
    deleteTenancyCheck() {
      this.toggleMenu();
      this.$emit("deleteTenancyCheck");
    },
    goBack() {
      this.$emit("goBack");
    },
  },
})
export default class HeaderMini extends Vue {}
