import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  class: "p-4"
}
const _hoisted_3 = { class: "pt-4 px-4" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 3,
  class: "pb-4 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_Input = _resolveComponent("Input")!
  const _component_ListItemBasic = _resolveComponent("ListItemBasic")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingScreen, {
      loaded: _ctx.loaded,
      message: _ctx.loadingMessage
    }, null, 8, ["loaded", "message"]),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.staff)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AddButton, {
                  name: _ctx.$t('global.defaults.addRemote'),
                  to: '/local-admin/remote-users/add',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearInput()))
                }, null, 8, ["name"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Input, {
              value: _ctx.nameFilter,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nameFilter) = $event)),
              onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterByName(_ctx.nameFilter))),
              "input-type": "text",
              label: _ctx.$t('inputs.filter.label'),
              id: "filter",
              class: "custom-bg",
              maxLength: _ctx.inputMaxLengths.name,
              whiteBg: true,
              filter: true,
              onClearInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearInput())),
              placeholder: _ctx.$t('inputs.filter.placeholder')
            }, null, 8, ["value", "label", "maxLength", "placeholder"])
          ]),
          (_ctx.staff)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                  return (_openBlock(), _createBlock(_component_ListItemBasic, {
                    key: user.userId,
                    icon: "remote-user",
                    name: user.name,
                    room: user.role,
                    status: user.status
                  }, null, 8, ["name", "room", "status"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.staff)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                  return (_openBlock(), _createBlock(_component_ListItem, {
                    key: user.userId,
                    icon: "remote-user",
                    name: user.name,
                    room: user.role,
                    status: user.status,
                    to: '/local-admin/remote-users/' + user.userId,
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearInput()))
                  }, null, 8, ["name", "room", "status", "to"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.staff && (!_ctx.users || _ctx.users.length === 0))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("global.defaults.noUsers", {
            userType: _ctx.$t("global.defaults.remoteUser", 2),
          })), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}