
import { Options, Vue } from "vue-class-component";
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ModalDeleteCheck from "@/components/global/ModalDeleteCheck.vue";
import { mapAwsUserStatus } from "@/utils/awsMappings";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import { getPrettyDate } from "@/utils/prettyDate";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderMini,
    HeaderShadow,
    InfoItem,
    LoadingScreen,
    ModalDeleteCheck,
    ErrorBox,
  },
  data() {
    return {
      user: {},
      loaded: false,
      loadingMessage: this.$t("global.defaults.loadingLocal"),
      deleteCheck: false,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
    };
  },
  computed: {
    localAdmin() {
      return this.$store.getters.getLocalAdmin;
    },
    currentUser() {
      return this.$store.getters.getUserObject;
    },
  },
  beforeMount() {
    this.getUser();
  },
  mounted() {
    document.title =
      this.$t("global.defaults.localUser", 1) +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    getUser() {
      this.$store
        .dispatch("getLocalUser", this.$route.params.userId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            this.user = response.data;
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    edit() {
      this.$router.push({
        name: "localUsersEdit",
        params: { usedId: this.$route.params.userId },
      });
    },
    resend() {
      this.loaded = false;
      this.$store
        .dispatch("reinviteLocalUser", this.$route.params.userId)
        .then((response: Record<string, unknown>) => {
          if (response) {
            this.getUser();
            console.log("invite local", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    deleteUser() {
      this.loaded = false;
      this.loadingMessage = this.$t("global.defaults.deletingLocal");
      this.$store
        .dispatch("deleteLocalUser", this.$route.params.userId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            this.$store.dispatch("changeUpdate", "localUser");
            this.$router.push({
              name: "localUsers",
            });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    getDate(date: Date) {
      return getPrettyDate(date);
    },
    mapUserStatus(cognitoStatus: string): string {
      return this.$t(mapAwsUserStatus(cognitoStatus));
    },
  },
})
export default class LocalUserDetail extends Vue {}
