import { AxiosResponse } from "axios";
import { i18n } from "../main";
import { mapAwsUserStatus } from "./awsMappings";

export function getLocalisedError(response: AxiosResponse): string {
  if (response.data.messageKey) {
    return i18n.global.t(
      response.data.messageKey,
      translateParams(response.data.messageParams)
    );
  }
  return response.data.message;
}

function translateParams(messageParams: any): any {
  const translatedParams = { ...messageParams };
  if (messageParams?.state) {
    translatedParams.state = i18n.global.t(
      mapAwsUserStatus(messageParams.state)
    );
  }
  return translatedParams;
}
