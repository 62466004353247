import { Commit } from "vuex";
import axios from "axios";
import axiosAuthenticated from "@/utils/axiosInstance";
import { AxiosResponse } from "axios";

import { RemoteSession } from "../types/session";

const baseUrl = process.env.VUE_APP_API_URL?.endsWith("/")
  ? process.env.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL + "/";

const axiosUnauthenticated = axios.create({
  baseURL: baseUrl,
});

export interface CommitFunction {
  commit: Commit;
}

export interface JoinSessionPayload {
  username: string;
  inviteId: string;
  accessCode: string;
}

export interface DeclineInvite {
  inviteId: string;
  reason: string;
}

export interface EditSession {
  sessionId: string;
  data: unknown;
}
export interface VideoTagSize {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface TelestrationState {
  shapeType: string;
  strokeWidth: string;
  activeColour: string;
  eraseMode: boolean;
  gridActive: boolean;
  held: boolean;
}

export interface Broadcast {
  id: string;
  name: string;
  telestrationId: string;
  thumbnailData: string;
}

export default {
  state: {
    session: {} as RemoteSession,
    video: {} as VideoTagSize,
    telestration: {
      shapeType: "path",
      strokeWidth: "6",
      activeColour: "red",
      eraseMode: false,
      gridActive: false,
      held: false,
    } as TelestrationState,
    telestrationMobileActive: true,
    isTouchDevice: false,
    isMobile: false,
    isTablet: false,
    isApple: false,
    audioMuted: false,
    micMuted: false,
    reconnect: 0,
    liveBroadcast: {} as Broadcast,
    inputDeviceId: null,
    inputDeviceAvailable: false,
    outputDeviceId: null,
    outputDeviceAvailable: false,
  },

  getters: {
    getActiveSession: (session: RemoteSession): RemoteSession => {
      return session;
    },
    getPeerConfiguration: (state: any) => {
      return {
        stunUrl: state.session.stunUrl,
        turnUrl: state.session.turnUrl,
        turnUser: state.session.turnUser,
        turnPass: state.session.turnPass,
      };
    },
  },
  mutations: {
    SET_ACTIVE_SESSION(
      state: Record<string, unknown>,
      data: RemoteSession
    ): void {
      state.session = data;
      console.log("State session", state.session);
    },
    SET_VIDEO_TAG(state: Record<string, unknown>, data: VideoTagSize): void {
      state.video = data;
    },
    SET_TELESTRATION_MOBILE_ACTIVE(
      state: Record<string, unknown>,
      boolean: boolean
    ): void {
      state.telestrationMobileActive = boolean;
      console.log("Telestration Active", boolean);
    },
    SET_AUDIO_MUTED(state: Record<string, unknown>, boolean: boolean): void {
      state.audioMuted = boolean;
      console.log("audioMuted", boolean);
    },
    SET_MIC_MUTED(state: Record<string, unknown>, boolean: boolean): void {
      state.micMuted = boolean;
      console.log("micMuted", boolean);
    },
    SET_IS_TOUCH_DEVICE(
      state: Record<string, unknown>,
      boolean: boolean
    ): void {
      state.isTouchDevice = boolean;
      console.log("TouchDevice", boolean);
    },
    SET_IS_MOBILE(state: Record<string, unknown>, boolean: boolean): void {
      state.isMobile = boolean;
      console.log("Mobile", boolean);
    },
    SET_IS_TABLET(state: Record<string, unknown>, boolean: boolean): void {
      state.isTablet = boolean;
      console.log("Tablet", boolean);
    },
    SET_IS_APPLE(state: Record<string, unknown>, boolean: boolean): void {
      state.isApple = boolean;
      console.log("Apple", boolean);
    },
    SET_TELESTRATION_SHAPE_TYPE(
      state: Record<string, unknown>,
      shapeType: string
    ): void {
      (state.telestration as TelestrationState).shapeType = shapeType;
    },
    SET_TELESTRATION_STROKE_WIDTH(
      state: Record<string, unknown>,
      strokeWidth: string
    ): void {
      (state.telestration as TelestrationState).strokeWidth = strokeWidth;
    },
    SET_TELESTRATION_COLOUR(
      state: Record<string, unknown>,
      colour: string
    ): void {
      (state.telestration as TelestrationState).activeColour = colour;
    },
    SET_TELESTRATION_ERASE_MODE(
      state: Record<string, unknown>,
      eraseMode: boolean
    ): void {
      (state.telestration as TelestrationState).eraseMode = eraseMode;
    },
    SET_TELESTRATION_GRID_ACTIVE(
      state: Record<string, unknown>,
      gridActive: boolean
    ): void {
      (state.telestration as TelestrationState).gridActive = gridActive;
    },
    SET_TELESTRATION_IMAGE_HELD(
      state: Record<string, unknown>,
      held: boolean
    ): void {
      (state.telestration as TelestrationState).held = held;
    },
    INCREMENT_RECONNECT(state: Record<string, unknown>): void {
      (state.reconnect as number)++;
    },
    SET_LIVE_BROADCAST(state: Record<string, unknown>, data: Broadcast): void {
      state.liveBroadcast = data;
      console.log("Live Broadcast", state.liveBroadcast);
    },
    SET_INPUT_DEVICE_ID(state: Record<string, unknown>, id: string): void {
      state.inputDeviceId = id;
      console.log("inputDeviceId", id);
    },
    SET_INPUT_DEVICE_AVAILABLE(
      state: Record<string, unknown>,
      available: boolean
    ): void {
      state.inputDeviceAvailable = available;
    },
    SET_OUTPUT_DEVICE_ID(state: Record<string, unknown>, id: string): void {
      state.outputDeviceId = id;
      console.log("outputDeviceId", id);
    },
    SET_OUTPUT_DEVICE_AVAILABLE(
      state: Record<string, unknown>,
      available: boolean
    ): void {
      state.outputDeviceAvailable = available;
    },
  },
  actions: {
    async isTouchDevice(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_IS_TOUCH_DEVICE", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async isMobile(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_IS_MOBILE", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async isTablet(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_IS_TABLET", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },
    async isApple(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_IS_APPLE", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async telestrationMobileActive(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_TELESTRATION_MOBILE_ACTIVE", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async audioMuted(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_AUDIO_MUTED", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async micMuted(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      boolean: boolean
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_MIC_MUTED", boolean);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getVideoTag(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      data: VideoTagSize
    ): Promise<AxiosResponse | null | void> {
      try {
        commit("SET_VIDEO_TAG", data);
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getSessions(): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.get("session");
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async getSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      sessionId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.get("session/" + sessionId);
        commit("SET_ACTIVE_SESSION", response.data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async removeActiveSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction
    ): Promise<AxiosResponse | null | void> {
      commit("SET_ACTIVE_SESSION", {});
    },

    async getInvitationInfo(
      _: any,
      inviteId: string
    ): Promise<AxiosResponse | null | void> {
      console.log("getInvitationInfo", inviteId);
      try {
        return await axiosUnauthenticated.post("session/invitation", {
          inviteId,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async resendAccessCode(
      _: any,
      inviteId: string
    ): Promise<AxiosResponse | null | void> {
      console.log("resendAccessCode", inviteId);
      try {
        return await axiosUnauthenticated.post("session/resendcode", {
          inviteId,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async joinSession(
      { commit }: CommitFunction,
      { username, inviteId, accessCode }: JoinSessionPayload
    ): Promise<void> {
      try {
        console.log("joinSession", username, inviteId, accessCode);
        const response = await axiosUnauthenticated.post("session/join", {
          inviteId,
          accessCode,
        });

        if (response.status === 200 && response.data) {
          commit("SET_ACTIVE_SESSION", {
            ...response.data,
          } as RemoteSession);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async declineSession(
      { commit }: CommitFunction,
      { inviteId, reason }: DeclineInvite
    ): Promise<AxiosResponse | null | void> {
      try {
        console.log("declineSession", inviteId, reason);
        const response = await axiosUnauthenticated.post("session/decline", {
          inviteId,
          reason,
        });
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async addSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      data: unknown
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post("session", data);
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async addUserToSession(
      _: any,
      { sessionId, data }: EditSession
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post(
          "session/" + sessionId + "/invite",
          data
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async removeUserFromSession(
      _: any,
      { sessionId, data }: EditSession
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post(
          "session/" + sessionId + "/remove",
          data
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async reinviteUserToSession(
      _: any,
      { sessionId, data }: EditSession
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post(
          "session/" + sessionId + "/reinvite",
          data
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async editSession(
      _: any,
      { sessionId, data }: EditSession
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.put(
          "session/" + sessionId,
          data
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async deleteSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      sessionId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.delete(
          "session/" + sessionId
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async startSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      sessionId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post(
          "session/" + sessionId + "/start"
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    async endSession(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: CommitFunction,
      sessionId: string
    ): Promise<AxiosResponse | null | void> {
      try {
        const response = await axiosAuthenticated.post(
          "session/" + sessionId + "/end"
        );
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error("Error", err.response);
        throw err.response;
      }
    },

    setTelestrationShapeType(
      { commit }: CommitFunction,
      shapeType: string
    ): void {
      commit("SET_TELESTRATION_SHAPE_TYPE", shapeType);
    },

    setTelestrationStrokeWidth(
      { commit }: CommitFunction,
      strokeWidth: string
    ): void {
      commit("SET_TELESTRATION_STROKE_WIDTH", strokeWidth);
    },

    setTelestrationColour({ commit }: CommitFunction, colour: string): void {
      commit("SET_TELESTRATION_COLOUR", colour);
    },

    setTelestrationEraseMode(
      { commit }: CommitFunction,
      eraseMode: boolean
    ): void {
      commit("SET_TELESTRATION_ERASE_MODE", eraseMode);
    },

    setTelestrationGridActive(
      { commit }: CommitFunction,
      gridActive: boolean
    ): void {
      commit("SET_TELESTRATION_GRID_ACTIVE", gridActive);
    },

    setTelestrationHeld({ commit }: CommitFunction, held: boolean): void {
      commit("SET_TELESTRATION_IMAGE_HELD", held);
    },

    sessionReconnect({ commit }: CommitFunction): void {
      commit("INCREMENT_RECONNECT");
    },

    setLiveBroadcast({ commit }: CommitFunction, broadcast: Broadcast): void {
      commit("SET_LIVE_BROADCAST", broadcast);
    },

    setInputDeviceID({ commit }: CommitFunction, id: string): void {
      commit("SET_INPUT_DEVICE_ID", id);
    },
    setInputDeviceAvailable(
      { commit }: CommitFunction,
      available: boolean
    ): void {
      commit("SET_INPUT_DEVICE_AVAILABLE", available);
    },

    setOutputDeviceID({ commit }: CommitFunction, id: string): void {
      commit("SET_OUTPUT_DEVICE_ID", id);
    },
    setOutputDeviceAvailable(
      { commit }: CommitFunction,
      available: boolean
    ): void {
      commit("SET_OUTPUT_DEVICE_AVAILABLE", available);
    },
  },
};
