import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56eca0f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-xl mb-6"
}
const _hoisted_2 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "absolute bottom-0 left-0 w-full h-28 px-4 shadow-xl-top"
}
const _hoisted_6 = { class: "flex items-center justify-items-center h-full" }
const _hoisted_7 = { class: "mr-2 w-1/2" }
const _hoisted_8 = { class: "ml-2 w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_TextLink = _resolveComponent("TextLink")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "scrollBox",
      class: _normalizeClass(["bg-white p-4 overflow-y-auto", { 'scroll-box': !_ctx.isStatic }])
    }, [
      (!_ctx.isStatic)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("pages.eulaPrivacyPolicy.pleaseRead")), 1))
        : _createCommentVNode("", true),
      (_ctx.isStatic)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_TextLink, {
              to: "/",
              name: _ctx.$t('buttons.backToSignIn')
            }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.eulaDoc)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            innerHTML: _ctx.eulaDoc
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.privacyDoc)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            innerHTML: _ctx.privacyDoc
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isStatic)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ButtonSecondary, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout())),
                type: "button",
                name: _ctx.$t('buttons.cancel')
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accept())),
                type: "button",
                name: _ctx.$t('buttons.accept'),
                disabled: !_ctx.activateButtons || _ctx.loading,
                loading: _ctx.loading
              }, null, 8, ["name", "disabled", "loading"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}