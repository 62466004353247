import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-25px bg-black text-white text-sm flex justify-between" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "px-2" }
const _hoisted_4 = { class: "px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextLink = _resolveComponent("TextLink")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t("global.footer.copyright")), 1),
    _createElementVNode("div", null, "v" + _toDisplayString(_ctx.version), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TextLink, {
          to: "/changelog",
          name: _ctx.$t('global.footer.linkChangeLog'),
          white: true
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TextLink, {
          to: "/eula-privacy-policy?static=true",
          name: _ctx.$t('global.footer.linkEULAPrivacyPolicy'),
          white: true
        }, null, 8, ["name"])
      ])
    ])
  ]))
}