
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    to: String,
  },
})
export default class AddButton extends Vue {}
