<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import AppLayoutDefault from "./AppLayoutDefault.vue";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AppLayout",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const layout = shallowRef(AppLayoutDefault);
    const route = useRoute();
    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = await require(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default || AppLayoutDefault;
        } catch (e) {
          layout.value = AppLayoutDefault;
        }
      }
    );
    return { layout };
  },
};
</script>
