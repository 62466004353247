
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import SelectCountry from "@/components/utils/SelectCountry.vue"; // @ is an alias to /src
import CountryCodesJson from "@/store/data/countryCodes.json"; // @ is an alias to /src
import CountryCodesCommonJson from "@/store/data/countryCodesCommon.json"; // @ is an alias to /src
import Checkbox from "@/components/utils/Checkbox.vue"; // @ is an alias to /src
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import parsePhoneNumber from "libphonenumber-js";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    Button,
    ButtonSecondary,
    HeaderShadow,
    HeaderMini,
    Input,
    Select,
    SelectCountry,
    Checkbox,
    LoadingScreen,
    ErrorBox,
  },
  data() {
    return {
      countryCodes: CountryCodesJson,
      countryCodesCommon: CountryCodesCommonJson,
      localUser: {},
      error: {
        email: "",
        phone: "",
      },
      correctNumber: "",
      loading: false,
      loaded: false,
      loadingMessage: this.$t("global.defaults.loadingLocal"),
      inputMaxLengths: InputMaxLengths[0],
      errorHeading: "",
      errorContent: "",
    };
  },
  beforeMount() {
    this.getLocalUser();
  },
  mounted() {
    document.title =
      this.$t("global.defaults.editLocal") +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    getCountryCallingCode() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let phoneNumber: any;
      phoneNumber = parsePhoneNumber(this.localUser.phone);
      this.localUser.phone = phoneNumber.nationalNumber;
      this.localUser.countryCode = String("+" + phoneNumber.countryCallingCode);
    },
    getLocalUser() {
      this.$store
        .dispatch("getLocalUser", this.$route.params.userId)
        .then((response: Record<string, unknown>) => {
          if (response.data) {
            this.loaded = true;
            this.localUser = response.data;
            this.getCountryCallingCode();
            this.getRole();
          }
        });
    },
    getRole() {
      if (this.localUser.role === "LOCAL_ADMIN") {
        this.localUser.role = true;
      } else {
        this.localUser.role = false;
      }
    },
    editLocalUser() {
      this.loading = true;

      const number = this.correctNumber
        ? this.correctNumber
        : this.remoteUser.phone;

      let role = "STAFF";
      if (this.localUser.role) role = "LOCAL_ADMIN";

      const data = {
        name: this.localUser.name,
        email: this.localUser.email,
        phone: number,
        role: role,
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$store
        .dispatch("editLocalUser", {
          userId: this.localUser.userId,
          data: data,
        })
        .then((response: any) => {
          if (response) {
            // this.verifyUser(response.data.userId);
            this.$store.dispatch("changeUpdate", "localUser");
            this.pushToDetail();
            console.log("edit local", response);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorFixed = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    pushToDetail() {
      this.$router.push({
        name: "localUsersDetail",
        params: { userId: this.localUser.userId },
      });
    },
    validateEmail(email: string) {
      const mailformat =
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (this.required(email)) {
        if (!email.match(mailformat)) {
          this.error.email = this.$t("inputs.email.errorInvalid");
          return false;
        } else {
          this.error.email = "";
          return true;
        }
      } else {
        return false;
      }
    },
    validatePhoneNumber(code: any, phone: any) {
      const phoneNumber = parsePhoneNumber(
        code + phone.replace(/^0+/, "").replace(/\s/g, "")
      );

      if (this.required(phone)) {
        if (phoneNumber?.isValid()) {
          this.error.phone = "";
          this.correctNumber = phoneNumber.number;
          return true;
        } else {
          this.error.phone = this.$t("inputs.phone.errorInvalid");
          return false;
        }
      } else {
        return false;
      }
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
  },
})
export default class LocalUsersEdit extends Vue {}
