
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:value", "updateInputType"],
  props: {
    label: String,
    value: String,
    options: Array,
    optionsCommon: Array,
    id: String,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    // Update Model Value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue(event: any) {
      this.$emit("update:value", event.target.value);
    },
  },
})
export default class Select extends Vue {}
