
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      statusColour: "text-nucleus-text",
    };
  },
  props: {
    heading: String,
    value: [String, Number],
    noPadding: Boolean,
  },
  mounted() {
    this.getStatusColor();
  },
  methods: {
    getStatusColor() {
      const heading = this.heading.toLowerCase();
      if (heading.includes("status")) {
        const text = this.value.toLowerCase();
        switch (text) {
          case "active":
            this.statusColour = "text-nucleus-primaryBlue font-bold";
            break;
          case "pending":
            this.statusColour = "text-nucleus-text";
            break;
          default:
            this.statusColour = "text-nucleus-text";
        }
      }
    },
  },
})
export default class InfoItem extends Vue {}
