
import { Options, Vue } from "vue-class-component";
import HeaderMini from "@/components/global/HeaderMini.vue"; // @ is an alias to /src
import HeaderShadow from "@/components/utils/HeaderShadow.vue"; // @ is an alias to /src
import InfoItem from "@/components/utils/InfoItem.vue"; // @ is an alias to /src
import Input from "@/components/utils/Input.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import SelectCountry from "@/components/utils/SelectCountry.vue"; // @ is an alias to /src
import HintLink from "@/components/utils/HintLink.vue"; // @ is an alias to /src
import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import router from "@/router";

import CountryCodesJson from "@/store/data/countryCodes.json"; // @ is an alias to /src
import CountryCodesCommonJson from "@/store/data/countryCodesCommon.json"; // @ is an alias to /src
import ApiVersionOptionsJson from "@/store/data/apiVersionOptions.json";
import RegionsJson from "@/store/data/regions.json";
import LanguagesJson from "@/store/data/languages.json";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import parsePhoneNumber from "libphonenumber-js";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  components: {
    HeaderMini,
    HeaderShadow,
    InfoItem,
    Input,
    Select,
    SelectCountry,
    HintLink,
    Button,
    ButtonSecondary,
    ErrorBox,
  },
  data() {
    return {
      apiVersionOptions: ApiVersionOptionsJson,
      countryCodes: CountryCodesJson,
      countryCodesCommon: CountryCodesCommonJson,
      regions: RegionsJson,
      tenancy: {
        name: "",
        contact: {
          name: "",
          email: "",
          countryCode: CountryCodesCommonJson[0].dial_code,
          phone: "",
        },
        api: {
          version: ApiVersionOptionsJson[0].id,
          username: "",
          password: "",
        },
        region: RegionsJson[0].id,
        language: LanguagesJson[0].id,
        license: "",
        licenseExpiry: "",
        initialCredits: "0",
      },
      localAdmin: {
        name: "",
        email: "",
        countryCode: CountryCodesCommonJson[0].dial_code,
        phone: "",
        role: "LOCAL_ADMIN",
        tenantId: "",
      },
      error: {
        tenancy: {
          contact: {
            email: "",
            phone: "",
          },
        },
        localAdmin: {
          email: "",
          phone: "",
        },
        initialCredits: 0,
        licenseExpiry: 0,
      },
      correctNumberTenant: "",
      correctNumberAdmin: "",
      passwordInputType: "password",
      passwordIcon: "show",
      loading: false,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  computed: {
    languages() {
      return LanguagesJson.filter((language) => {
        const region =
          this.regions.find(
            (v: any) => v.id.toString() === this.tenancy.region
          ) || this.regions[0];
        return region.languages?.includes(language.locale);
      });
    },
  },
  watch: {
    languages(newLanguages) {
      // Change tenancy language if selected language no longer supported
      if (
        newLanguages.filter(
          (language: any) => language.locale === this.tenancy.language
        ).length === 0
      ) {
        this.tenancy.language = newLanguages[0].id;
      }
    },
  },
  methods: {
    addTenancy() {
      this.loading = true;

      const isoDate = new Date(this.tenancy.licenseExpiry).toISOString();

      console.log("iso Date", isoDate);

      const data = {
        name: this.tenancy.name,
        contact: {
          countryCode: this.tenancy.contact.countryCode,
          email: this.tenancy.contact.email,
          name: this.tenancy.contact.name,
          phone: this.correctNumberTenant,
        },
        region: this.tenancy.region,
        language: this.tenancy.language,
        api: this.tenancy.api,
        license: this.tenancy.license,
        licenseExpiry: isoDate,
        initialCredits: parseInt(this.tenancy.initialCredits),
        maxBroadcasts: parseInt(this.tenancy.maxBroadcasts),
      };

      this.$store
        .dispatch("addTenancy", data)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.data) {
            this.$store.dispatch("changeUpdate", "tenancy");
            this.localAdmin.tenantId = response.data.tenantId;
            this.addLocalUser();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    addLocalUser() {
      const data = {
        name: this.localAdmin.name,
        email: this.localAdmin.email,
        phone: this.correctNumberAdmin,
        role: this.localAdmin.role,
        tenantId: this.localAdmin.tenantId,
      };
      this.$store
        .dispatch("addLocalUser", data)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response) {
            console.log("add local admin", response);
            this.loading = false;
            router.push({ name: "tenancies" });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loading = false;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    validateEmail(email: string) {
      const mailformat =
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (this.required(email)) {
        if (!email.match(mailformat)) {
          this.error.email = this.$t("inputs.email.errorInvalid");
          return false;
        } else {
          this.error.email = "";
          return true;
        }
      } else {
        return false;
      }
    },
    // Refactor this!
    validatePhoneNumberTenant(code: any, phone: any) {
      const phoneNumberTenant = parsePhoneNumber(
        code + phone.replace(/^0+/, "").replace(/\s/g, "")
      );

      if (this.required(phone)) {
        if (phoneNumberTenant?.isValid()) {
          this.error.tenancy.contact.phone = "";
          this.correctNumberTenant = phoneNumberTenant.number;
          return true;
        } else {
          this.error.tenancy.contact.phone = this.$t(
            "inputs.phone.errorInvalid"
          );
          return false;
        }
      } else {
        return false;
      }
    },
    validatePhoneNumberAdmin(code: any, phone: any) {
      const phoneNumber = parsePhoneNumber(
        code + phone.replace(/^0+/, "").replace(/\s/g, "")
      );

      if (this.required(phone)) {
        if (phoneNumber?.isValid()) {
          this.error.localAdmin.phone = "";
          this.correctNumberAdmin = phoneNumber.number;
          return true;
        } else {
          this.error.localAdmin.phone = this.$t("inputs.phone.errorInvalid");
          return false;
        }
      } else {
        return false;
      }
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
  },
})
export default class TenancyAdd extends Vue {}
